import PATHS from "utils/paths";
import AccountIcon from "components/Icons/account";
import PlansIcon from "components/Icons/plans";
import BillingIcon from "components/Icons/billing";
import CodeIcon from "components/Icons/code";
import ProfileIcon from "components/Icons/profile";
import SalesIcon from "components/Icons/sales";
import PagesIcon from "components/Icons/pages";
import ProductsIcon from "components/Icons/products";
import CouponsIcon from "components/Icons/coupons";
import SettingsIcon from "components/Icons/settings";
import HomeIcon from "components/Icons/home";
import LinksIcon from "components/Icons/links";
import ChannelsIcon from "components/Icons/channels";
import TaxRatesIcon from "components/Icons/taxRates";
import FilesIcon from "components/Icons/files";
import TutorialIcon from "components/Icons/tutorial";
import FlashIcon from "components/Icons/flash";
import LearnIcon from "components/Icons/learn";
import CampaignsIcon from "components/Icons/campaigns";
import CreatorsIcon from "components/Icons/creators";
import SubscriptionsIcon from "components/Icons/subscriptions";
import AnalyticsIcon from "components/Icons/analytics";
import ShippingRatesIcon from "components/Icons/shippingRates";
import StripeIcon from "components/Icons/stripe";
import ZapierIcon from "components/Icons/zapier";
import WebflowIcon from "components/Icons/webflow";
import QRCodeIcon from "components/Icons/qrCode";

import { COLOR_SCHEME_KEYS } from "utils/styles";
import { AuthedPricingHOC, AuthedBillingHOC } from "./hocs";

const DEFAULT_STYLE = { highlight: true };
const ROUTE_DEFAULTS = {
  style: DEFAULT_STYLE
};

/**
 * ================
 * Authed
 * ================
 */

export const AUTHED_PROFILE = {
  ...ROUTE_DEFAULTS,
  href: PATHS.PROFILE,
  title: "Profile",
  icon: ProfileIcon,
  description: ["Your profile and account information"]
};

export const AUTHED_HOME = ({ href }) => ({
  ...ROUTE_DEFAULTS,
  href,
  title: "Home",
  icon: HomeIcon,
  description: ["Overview of your PriceBlocs app."]
});

export const AUTHED_SALES = ({ href }) => ({
  ...ROUTE_DEFAULTS,
  href,
  title: "Sales",
  icon: SalesIcon,
  description: ["Your sales from pages and links."]
});

export const AUTHED_LINKS = ({ href }) => ({
  ...ROUTE_DEFAULTS,
  href,
  title: "Payment Links",
  icon: LinksIcon,
  description: ["Your one click checkout payment links."]
});

export const AUTHED_CONTACTLESS = ({ href }) => ({
  ...ROUTE_DEFAULTS,
  href,
  title: "Contactless",
  icon: QRCodeIcon,
  description: ["Accept payments with custom QR codes."]
});

export const AUTHED_PAGES = ({ href }) => ({
  ...ROUTE_DEFAULTS,
  href,
  title: "Pages",
  icon: PagesIcon,
  description: ["Your hosted payment pages."]
});

export const AUTHED_PRODUCTS = ({ href }) => ({
  ...ROUTE_DEFAULTS,
  href,
  title: "Products",
  icon: ProductsIcon,
  description: ["Your products for sale."]
});

export const AUTHED_COUPONS = ({ href }) => ({
  ...ROUTE_DEFAULTS,
  href,
  title: "Coupons",
  icon: CouponsIcon,
  description: ["Your coupons for applying discounts."]
});

export const AUTHED_ANALYTICS = ({ href }) => ({
  ...ROUTE_DEFAULTS,
  href,
  title: "Analytics",
  icon: AnalyticsIcon,
  description: ["Metrics for your pages and links."]
});

export const AUTHED_INSIGHTS = ({ href }) => ({
  ...ROUTE_DEFAULTS,
  href,
  title: "Insights",
  icon: AnalyticsIcon,
  description: ["Insights into revenue growth and expansion."]
});

export const AUTHED_TAX_RATES = ({ href }) => ({
  ...ROUTE_DEFAULTS,
  href,
  title: "Tax Rates",
  icon: TaxRatesIcon,
  description: ["Tax rates for your products."]
});

export const AUTHED_SHIPPING_RATES = ({ href }) => ({
  ...ROUTE_DEFAULTS,
  href,
  title: "Shipping Rates",
  icon: ShippingRatesIcon,
  description: ["Shipping rates for your products."]
});

export const AUTHED_FILES = ({ href }) => ({
  ...ROUTE_DEFAULTS,
  href,
  title: "Files",
  icon: FilesIcon,
  description: ["Files and content to sell with your products."]
});

export const AUTHED_CAMPAIGNS = ({ href }) => ({
  ...ROUTE_DEFAULTS,
  href,
  title: "Campaigns",
  icon: CampaignsIcon,
  description: ["Campaigns to get paid."]
});

export const AUTHED_SETTINGS = ({ href }) => ({
  ...ROUTE_DEFAULTS,
  href,
  title: "Settings",
  icon: SettingsIcon,
  description: ["Manage your app settings."]
});

export const AUTHED_ACCOUNT = {
  ...ROUTE_DEFAULTS,
  href: PATHS.ACCOUNT,
  title: "Account",
  icon: AccountIcon,
  description: ["Your account and connected merchant account information"]
};

export const AUTHED_DEVELOPMENT = {
  ...ROUTE_DEFAULTS,
  href: PATHS.DEVELOPMENT,
  title: "Development",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: CodeIcon,
  description: ["Your information for secure integrations"]
};

export const AUTHED_PRICING = {
  ...ROUTE_DEFAULTS,
  hoc: AuthedPricingHOC,
  title: "Plans",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: PlansIcon,
  description: ["Pick the right plan for you or your team"]
};

export const AUTHED_BILLING = {
  ...ROUTE_DEFAULTS,
  hoc: AuthedBillingHOC,
  title: "Billing",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: BillingIcon,
  description: [
    "See your invoices, manage your subscription and payment methods."
  ]
};

/**
 * ================
 * Unauthed
 * ================
 */
export const UNAUTHED_PRICING = {
  ...ROUTE_DEFAULTS,
  href: PATHS.PRICING,
  title: "Plans",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: PlansIcon,
  description: ["Pick the right plan for you or your team"]
};

export const UNAUTHED_LINKS = {
  ...ROUTE_DEFAULTS,
  href: PATHS.FEATURES_LINKS_BUILDER,
  title: "Builder",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: LinksIcon,
  description: [
    "Use our no-code editor to build a payment link in just a few clicks."
  ]
};

export const UNAUTHED_CONTACTLESS = {
  ...ROUTE_DEFAULTS,
  href: PATHS.FEATURES_CONTACTLESS,
  title: "Contactless",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: QRCodeIcon,
  callout: "New",
  description: ["Accept payments with custom QR codes."]
};

export const UNAUTHED_LINKS_ADVANCED = {
  ...ROUTE_DEFAULTS,
  href: PATHS.FEATURES_LINKS_ADVANCED,
  title: "Advanced links",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: FlashIcon,
  description: ["The most advanced Stripe payment links available."]
};

export const UNAUTHED_LINKS_API = {
  ...ROUTE_DEFAULTS,
  href: PATHS.FEATURES_LINKS_API,
  title: "Payment links API",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: CodeIcon,
  description: ["An easy to use REST API for Stripe payment links."]
};

export const UNAUTHED_PAGES = {
  ...ROUTE_DEFAULTS,
  href: PATHS.BUILDER_APPLICATIONS,
  title: "Payment Pages",
  callout: "New",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: PagesIcon,
  description: ["Build custom Stripe checkout forms you can embed anywhere."]
};

export const UNAUTHED_SUBSCRIPTIONS = {
  ...ROUTE_DEFAULTS,
  href: PATHS.FEATURES_ANALYTICS,
  title: "Subscriptions",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: SubscriptionsIcon,
  description: ["Build a page to sell a subscription, in just a few clicks."]
};

export const UNAUTHED_ANALYTICS = {
  ...ROUTE_DEFAULTS,
  href: PATHS.FEATURES_ANALYTICS,
  title: "Analytics",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: AnalyticsIcon,
  description: [
    "Funnel and conversion analytics for all of your payment links."
  ]
};

export const UNAUTHED_CHECKOUT = {
  ...ROUTE_DEFAULTS,
  href: PATHS.FEATURES_CHECKOUT,
  title: "One Click Checkout",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: SubscriptionsIcon,
  description: ["Get your customers to checkout in just one click."]
};

export const UNAUTHED_DIGITAL_CONTENT = {
  ...ROUTE_DEFAULTS,
  href: PATHS.FEATURES_DIGITAL_CONTENT,
  title: "Digital content",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: FilesIcon,
  description: [
    "Send your customers a content download link after a successful payment."
  ]
};

export const UNAUTHED_ZAPIER = {
  ...ROUTE_DEFAULTS,
  href: PATHS.FEATURES_INTEGRATIONS_ZAPIER,
  title: "Zapier",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: ZapierIcon,
  description: ["Connect to all of your favorite apps."]
};

export const UNAUTHED_STRIPE = {
  ...ROUTE_DEFAULTS,
  href: PATHS.FEATURES_INTEGRATIONS_STRIPE,
  title: "Stripe",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: StripeIcon,
  description: ["The most advanced set of no-code Stripe payment tools."]
};

export const UNAUTHED_WEBFLOW = {
  ...ROUTE_DEFAULTS,
  href: PATHS.FEATURES_INTEGRATIONS_WEBFLOW,
  title: "Webflow",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: WebflowIcon,
  description: ["One click checkout for your site, no backend required."]
};

export const UNAUTHED_CREATORS = {
  ...ROUTE_DEFAULTS,
  href: PATHS.FEATURES_PAGES_CREATORS,
  title: "Creators",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: CreatorsIcon,
  description: ["The fastest way to get paid by your supporters."]
};

export const UNAUTHED_CHANNELS = {
  ...ROUTE_DEFAULTS,
  href: PATHS.FEATURES_CHANNELS,
  title: "Channels",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: ChannelsIcon,
  description: ["See how payment links can be used in any channel."]
};

export const UNAUTHED_TUTORIALS = {
  ...ROUTE_DEFAULTS,
  href: PATHS.TUTORIALS,
  zone: PATHS.TUTORIALS,
  title: "Tutorials",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: TutorialIcon,
  description: [
    "Learn how to use PriceBlocs payment links across all of your channels."
  ]
};

export const UNAUTHED_LEARN = {
  ...ROUTE_DEFAULTS,
  href: PATHS.LEARN,
  zone: PATHS.LEARN,
  title: "Learn",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: LearnIcon,
  description: ["Learn how to configure and use your PriceBlocs account."]
};

export const UNAUTHED_DOCS = {
  ...ROUTE_DEFAULTS,
  href: PATHS.DOCS,
  zone: PATHS.DOCS,
  title: "API Reference",
  scheme: COLOR_SCHEME_KEYS.BLUE,
  icon: CodeIcon,
  description: [
    "Learn how to use our REST API to create and manage payment links."
  ]
};
