import React, { Fragment, useMemo } from "react";
import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CTA from "components/CTA";
import Nav from "components/Nav";
import { COLORS } from "utils/styles";
import { BUTTON_CLASS_CONTEXT, CTA_STYLE } from "utils/constants/ui";
import PATHS from "utils/paths";
import { v4 as uuidv4 } from "uuid";
import NavBarTopBlock from "components/NavBarTopBlock";

const ErrorReportCTA = ({ isAuthed, header, userEmail, userDislayName }) => {
  const copy = isAuthed ? "To hub" : "To home page";
  const href = isAuthed ? PATHS.HUB : PATHS.HOME;
  const errorUUID = useMemo(() => uuidv4(), []);

  const ctaProps = {
    header,
    subheader: `Something unexpected happened, but the team has been notified, and we'll get on it straight away. Tell us what happened to help us fix the issue.`,
    actions: [
      {
        copy: copy,
        tag: "link",
        classes: {
          contextClass: BUTTON_CLASS_CONTEXT.SECONDARY
        },
        href
      },
      {
        copy: `What happened?`,
        tag: "button",
        onClick: () => {
          const dialogProps = { eventId: errorUUID };
          if (isAuthed) {
            dialogProps.email = userEmail;
            dialogProps.name = userDislayName;
          }
          Sentry.showReportDialog(dialogProps);
        }
      }
    ],
    ...CTA_STYLE,
    customClasses: {
      actionsWrapper: "flex flex-row z-1 pt3 justify-between justify-end-ns"
    }
  };
  return (
    <Fragment>
      <Nav />
      <NavBarTopBlock />
      <div
        className="h-100 w-100"
        style={{ backgroundColor: COLORS.whitesmoke }}
      >
        <main className="ph3 pb3 black-80 h-100">
          <div className="measure center">
            <div className="pv3 pt4-ns dib w-100 ph0-ns">
              <CTA {...ctaProps} />
            </div>
          </div>
        </main>
      </div>
    </Fragment>
  );
};

ErrorReportCTA.propTypes = {
  header: PropTypes.string,
  isAuthed: PropTypes.bool
};

ErrorReportCTA.defaultProps = {
  header: "Whoops there was an error!"
};

const mapStateToProps = (state) => {
  const userID = state.getIn(["user", "data", "id"]);
  return {
    isAuthed: Boolean(userID),
    userUUID: state.getIn(["user", "data", "uuid"]),
    userDislayName: state.getIn(["user", "data", "display_name"]),
    userEmail: state.getIn(["user", "data", "email"])
  };
};

export default connect(mapStateToProps, null)(ErrorReportCTA);
