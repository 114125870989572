import React, { useMemo } from "react";
import random from "lodash/random";
import NextImage from "next/image";
import { PRIORITY_FILL } from "utils/constants/ui";
import { canRenderImages } from "utils/image";

export const CDN_PLACEHOLDER_IMAGE = `/assets/cdn__placeholder.png`;

export const PLACEHOLDER_IMAGE = `/assets/bg__cloudMask_0.png`;

const Image = ({ src, ...props }) => {
  const { canRender, hasCDNImages } = canRenderImages(src);
  const randomIndex = useMemo(() => random(0, 9), []);

  if (!canRender) {
    const extra = props.height || props.width ? props : PRIORITY_FILL;
    const placeholderProps = {
      src: hasCDNImages
        ? CDN_PLACEHOLDER_IMAGE
        : `/assets/bg__cloudMask_${randomIndex || 1}.png`,
      alt: props && props.title ? props.title : "placeholder",
      ...extra
    };
    return <NextImage {...placeholderProps} />;
  } else {
    return <NextImage src={src} {...props} />;
  }
};
export default Image;
