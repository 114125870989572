import C from "constants/taxRate";
import ActionHelpers from "./helpers";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { pluralizeCopy } from "utils/copy";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_TAX_RATE_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setTaxRates = (payload) => {
  return {
    type: C.SET_TAX_RATES,
    payload
  };
};

export const replaceTaxRates = (payload) => {
  return {
    type: C.REPLACE_TAX_RATES,
    payload
  };
};

export const removeTaxRates = (payload) => {
  return {
    type: C.REMOVE_TAX_RATES,
    payload
  };
};

/**
 * resetTaxRateNotes
 */
export function resetTaxRateNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_TAX_RATE_STORE,
      payload
    });
  };
}

export function showTaxRateNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(C.UPDATE_TAX_RATE_STORE, message || `Success!`)
    );
  };
}

/**
 * createMerchantAccountTaxRates
 */
export function createMerchantAccountTaxRates(
  { resourceUUID, resource, uuid, data: requestData },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_TAX_RATE_STORE,
        "get",
        "app",
        "Create merchant account taxRate"
      )
    );

    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/tax-rates`;

    return axios({
      method: "post",
      url: REQUEST_URL,
      data: requestData,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const { taxRates } = data;
        const actions = [ActionHelpers.clearLoader(C.UPDATE_TAX_RATE_STORE)];
        if (taxRates.length) {
          actions.push(
            ActionHelpers.successNote(
              C.UPDATE_TAX_RATE_STORE,
              `Created ${pluralizeCopy("tax rate", taxRates.length)}`
            )
          );
          actions.push(setTaxRates(taxRates));
        }

        dispatch(batchActions(actions));
        callback(null, { taxRates });
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_TAX_RATE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * updateMerchantAccountTaxRates
 */
export const updateMerchantAccountTaxRates = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/tax-rates`;

    return axios({
      method: "put",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: { taxRates } }) => {
        if (taxRates.length > 0) {
          dispatch(
            batchActions([
              setTaxRates(taxRates),
              ActionHelpers.successNote(C.UPDATE_TAX_RATE_STORE, "Updated")
            ])
          );
        }
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_TAX_RATE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * fetchMerchantAccountTaxRates
 */
export function fetchMerchantAccountTaxRates(
  { resourceUUID, resource, uuid, test },
  callback = () => {}
) {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID,
      test
    })}/merchant-accounts/${uuid}/tax-rates`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data: { taxRates } }) => {
        const actions = [ActionHelpers.clearLoader(C.UPDATE_TAX_RATE_STORE)];
        if (Array.isArray(taxRates) && taxRates.length) {
          actions.push(setTaxRates(taxRates));
        }

        dispatch(batchActions(actions));
        callback();
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_TAX_RATE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}
