import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import * as Sentry from "@sentry/react";
import ErrorReportCTA from "components/ErrorReportCTA";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorCount: 0
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    process.env.NODE_ENV === "production" &&
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });
        const { userID, userEmail } = this.props;
        if (userID && userEmail) {
          scope.setUser({
            id: userID,
            email: userEmail
          });
        }

        Sentry.captureException(error);
      });

    this.setState((state) => ({ errorCount: state.errorCount + 1 }));
  }

  render() {
    return (
      <Fragment key={this.state.errorCount}>
        {this.state.hasError
          ? this.props.fallback || <ErrorReportCTA />
          : this.props.children}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userID: state.getIn(["user", "data", "id"]),
    userEmail: state.getIn(["user", "data", "email"])
  };
};

export default connect(mapStateToProps, null)(ErrorBoundary);
