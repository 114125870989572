import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "components/Form/fields/Button";
import { resetAppNotes } from "actions/app";

import { resetApplicationNotes } from "actions/application";
import { resetDeploymentNotes } from "actions/deployment";
import { resetManifestNotes } from "actions/manifest";
import { resetProductNotes } from "actions/product";
import { resetPriceNotes } from "actions/price";
import { resetFeatureNotes } from "actions/feature";
import { resetFeatureGroupNotes } from "actions/featureGroup";
import { resetMerchantAccountNotes } from "actions/merchantAccount";
import { resetMetadataNotes } from "actions/metadata";
import { resetCapabilityNotes } from "actions/capability";
import { resetShortLinkNotes } from "actions/shortLink";
import { resetCouponNotes } from "actions/coupon";
import { resetTaxRateNotes } from "actions/taxRate";
import { resetShippingRateNotes } from "actions/shippingRate";
import { resetIntegrationNotes } from "actions/integration";
import { resetAttachmentNotes } from "actions/attachment";
import { resetCampaignNotes } from "actions/campaign";
import { resetDatasourceNotes } from "actions/datasource";
import { resetAudienceNotes } from "actions/audience";
import { resetInsightNotes } from "actions/insight";
import { resetContactlessConfigNotes } from "actions/contactlessConfig";
import { resetEntitlementConfigNotes } from "actions/entitlementConfig";
import { resetLegalContentNotes } from "actions/legalContent";
import { resetEditableContentNotes } from "actions/editableContent";
import { resetWorkflowNotes } from "actions/workflow";
import { resetWebhookNotes } from "actions/webhook";

import { resetAuthNotes } from "actions/auth";
import { resetUserNotes } from "actions/user";
import { resetOnboardingNotes } from "actions/onboarding";
import { resetLicenseNotes } from "actions/license";
import { resetPaymentMethodNotes } from "actions/paymentMethod";
import { resetSubscriptionNotes } from "actions/subscription";
import { resetOrganizationNotes } from "actions/organization";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { notesFromReducerState, noteMessage } from "utils/notification";
import IconButton from "components/IconButton";
import { COLORS } from "utils/styles";
import upperFirst from "lodash/upperFirst";
import { reducerKeys } from "reducers";
const DISMISS_TIMEOUT = 3000;

class AppNote extends Component {
  constructor() {
    super();
    this.dismissNote = this.dismissNote.bind(this);
  }

  dismissNote(reducer) {
    return () => {
      const reducerName = `reset${upperFirst(reducer)}Notes`;
      const noteResetFn = this.props.actions[reducerName];

      if (noteResetFn) {
        noteResetFn({ note: null });
      } else {
        console.warn(`No note reset fn for: ${reducerName}`);
      }
    };
  }

  componentDidUpdate(prevProps) {
    const { notes: prevNotes } = prevProps;
    const { notes } = this.props;
    // Notr: add better queue management for notes
    if (prevNotes && prevNotes.length !== notes.length) {
      notes.forEach((note) => {
        if (note.note.toJS) {
          if (note.note.toJS().autoClose) {
            setTimeout(() => {
              this.dismissNote(note.reducer)();
            }, DISMISS_TIMEOUT);
          }
        } else {
          console.error("Not immutable note: ", note.note);
        }
      });
    }
  }

  render() {
    const { notes } = this.props;

    if (notes.length) {
      const { note, reducer } = notes[0];
      const notification = noteMessage(note.toJS ? note.toJS() : note);
      const { message, actions, autoClose } = notification;
      let actionButtons;
      if (actions && actions.length && !autoClose) {
        const nodes =
          actions &&
          actions.map((action, actionIx) => (
            <Button
              key={actionIx}
              {...action}
              style={{ height: 26 }}
              classes={{
                button: `fw5 ph2 mh1 br2 pv2 border-box input-reset ba b--black bg-transparent pointer f6 dib white flex-shrink-0 flex link`
              }}
            />
          ));
        actionButtons = <div className="pt2 ph0 pt0-ns pr2-ns">{nodes}</div>;
      }
      // const notieCode = notieErrorCodeFromStatus(status);
      const iconButtonProps = {
        iconType: "close",
        colors: {
          hover: COLORS.primary,
          active: COLORS.primary,
          inactive: COLORS.primary
        }
      };

      return (
        <div
          className="app__note flex items-center justify-center pa3 navy fixed top-0 left-0 right-0 z-7 pointer"
          onClick={this.dismissNote(reducer)}
        >
          <div className="mw8 w-100 center flex flex-row justify-between items-start items-center-ns">
            <div className="flex flex-column flex-row-ns items-start items-center-ns">
              <span className="lh-title mr2 fw5">{message}</span>
              {actionButtons}
            </div>
            <IconButton {...iconButtonProps} />
          </div>
          <style jsx>{`
            .app__note {
              background-color: ${COLORS.water_blue};
            }
            .app__note:hover {
              background-color: ${COLORS.light_blue};
            }
          `}</style>
        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => {
  const notes = notesFromReducerState(reducerKeys, state);

  return {
    notes
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      resetAppNotes,
      resetApplicationNotes,
      resetDeploymentNotes,
      resetManifestNotes,
      resetProductNotes,
      resetPriceNotes,
      resetFeatureNotes,
      resetFeatureGroupNotes,
      resetAuthNotes,
      resetUserNotes,
      resetOnboardingNotes,
      resetLicenseNotes,
      resetPaymentMethodNotes,
      resetSubscriptionNotes,
      resetOrganizationNotes,
      resetMerchantAccountNotes,
      resetMetadataNotes,
      resetCapabilityNotes,
      resetShortLinkNotes,
      resetCouponNotes,
      resetTaxRateNotes,
      resetShippingRateNotes,
      resetIntegrationNotes,
      resetAttachmentNotes,
      resetCampaignNotes,
      resetDatasourceNotes,
      resetAudienceNotes,
      resetInsightNotes,
      resetContactlessConfigNotes,
      resetEntitlementConfigNotes,
      resetLegalContentNotes,
      resetEditableContentNotes,
      resetWorkflowNotes,
      resetWebhookNotes
    },
    dispatch
  )
});

AppNote.propTypes = {
  notes: PropTypes.array,
  actions: PropTypes.shape({
    resetAppNotes: PropTypes.func,
    resetFormerNotes: PropTypes.func,
    resetAuthNotes: PropTypes.func,
    resetUserNotes: PropTypes.func,
    resetOnboardingNotes: PropTypes.func,
    resetLicenseNotes: PropTypes.func
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(AppNote);
