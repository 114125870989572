import C from "constants/userDatasource";

export function setLoading() {
  return {
    type: C.UPDATE_USER_DATASOURCE_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setUserDatasources = (payload) => {
  return {
    type: C.SET_USER_DATASOURCES,
    payload
  };
};

export const replaceUserDatasources = (payload) => {
  return {
    type: C.REPLACE_USER_DATASOURCES,
    payload
  };
};
