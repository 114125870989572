import React from "react";
import PropTypes from "prop-types";
import Button from "components/Form/fields/Button";
import { COLORS } from "utils/styles";
import classnames from "classnames";
import assign from "lodash/assign";
import { ctaStyles } from "./ctaStyles";
const DEFAULT_CLASSES = {
  container: "br2 pa3 hairline-1 shadow-0 ba",
  imageWrapper: "w-100 flex flex-column",
  header: "f4 fw5 lh-title z-1",
  subheader: "f5 lh-copy pr2 pr0-ns z-1",
  footnote: "f6 lh-copy i pr2 pr0-ns z-1",
  subheaderCollection: "flex-column pv2 w-100",
  subheaderContainer: "flex flex-column justify-between items-end z-1",
  actionsWrapper: ""
};

const DEFAULT_STYLES = {
  container: {
    backgroundColor: COLORS.white
  }
};

const copyNodes = (content, className) => {
  const collection = Array.isArray(content) ? content : [content];
  return collection.map((sub, subIx) => (
    <div key={subIx} className={classnames(className, { pt1: subIx })}>
      {sub}
    </div>
  ));
};

const CTA = ({
  header,
  subheader,
  footnote,
  children,
  actions,
  childrenStyles,
  customClasses,
  customStyles
}) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const styles = { ...DEFAULT_STYLES, ...customStyles };
  const actionButtons =
    actions &&
    actions.map((action, actionIx) => {
      let actionClasses = {
        button:
          classes.button ||
          `z-1 fw6 br2 ph3 pv2 border-box input-reset ba b--black bg-transparent pointer f6 dib white ph4 flex-shrink-0 flex link ${
            actionIx ? "ml2" : ""
          }`
      };

      if (action.classes) {
        assign(actionClasses, action.classes);
      }

      return <Button key={actionIx} {...action} classes={actionClasses} />;
    });

  const subheaderNodes = subheader && copyNodes(subheader, classes.subheader);
  const footnoteNodes = footnote && copyNodes(footnote, classes.footnote);

  const multipleButtons = actionButtons.length > 1;
  const actionClasses =
    classes.actionsWrapper ||
    classnames("flex z-1 pt2", {
      "justify-between justify-end-ns": multipleButtons,
      "justify-end": !multipleButtons
    });

  return (
    <div className={classes.container} style={styles.container}>
      <div className={classes.imageWrapper}>
        {header && <div className={classes.header}>{header}</div>}
        <div className={classes.subheaderContainer}>
          {subheaderNodes && (
            <div className={classes.subheaderCollection}>{subheaderNodes}</div>
          )}
          {footnoteNodes && (
            <div className={classes.subheaderCollection}>{footnoteNodes}</div>
          )}
          {actionButtons && actionButtons.length > 0 && (
            <div style={{ flexShrink: 0 }} className={actionClasses}>
              {actionButtons}
            </div>
          )}
          {children ? (
            <div className="w-100" style={childrenStyles}>
              {children}
            </div>
          ) : null}
        </div>
      </div>
      <style jsx>{ctaStyles}</style>
    </div>
  );
};

CTA.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  footnote: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  customClasses: PropTypes.object,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      copy: PropTypes.string,
      tag: PropTypes.string,
      onClick: PropTypes.func,
      href: PropTypes.string
    })
  )
};

CTA.defaultProps = {
  actions: [],
  childrenStyles: { flexShrink: 0 }
};

export default CTA;
