import React from "react";
import NextHead from "next/head";
import PropTypes from "prop-types";

const metadataWarn = (message) => {
  let logMessage = [message];
  try {
    if (typeof window !== "undefined") {
      const hrefRoot = window.location.href.split("?")[0];
      if (hrefRoot) {
        logMessage.push(hrefRoot);
      }
    }
  } catch (error) {}
  console.warn(logMessage.join(" "));
};

const Head = (props) => (
  <NextHead>
    <title>{props.title}</title>
    {props.description ? (
      <meta name="description" content={props.description} />
    ) : (
      metadataWarn("No meta description")
    )}
    {props["icon:sizes"] && props["icon"] ? (
      <link rel="icon" sizes={props["icon:sizes"]} href={props["icon"]} />
    ) : (
      metadataWarn("No icon")
    )}

    {props["apple-touch-icon"] ? (
      <link rel="apple-touch-icon" href={props["apple-touch-icon"]} />
    ) : (
      metadataWarn("No apple touch icon")
    )}

    {props["mask-icon"] && props["mask-icon:color"] ? (
      <link
        rel="mask-icon"
        href={props["mask-icon"]}
        color={props["mask-icon:color"]}
      />
    ) : (
      metadataWarn("No mask icon")
    )}

    {props["og:url"] ? (
      <link rel="canonical" href={props["og:url"]} />
    ) : (
      metadataWarn("No og url")
    )}

    {/* OG Tags */}
    {[
      "og:title",
      "og:url",
      "og:description",
      "og:type",
      "og:image",
      "og:image:width",
      "og:image:height",
      "og:site_name",
      "twitter:title",
      "twitter:site",
      "twitter:card",
      "twitter:image",
      "twitter:description",
      "twitter:creator"
    ].reduce((memo, ogTag) => {
      if (props[ogTag]) {
        memo.push(<meta key={ogTag} property={ogTag} content={props[ogTag]} />);
      } else {
        metadataWarn(`Missing ${ogTag}`);
      }

      return memo;
    }, [])}

    {/* Google Meta */}
    {["name", "description", "image"].reduce((memo, tag) => {
      if (props[tag]) {
        memo.push(<meta key={tag} itemProp={tag} content={props[tag]} />);
      } else {
        metadataWarn(`Missing ${tag}`);
      }
      return memo;
    }, [])}
  </NextHead>
);

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  ogImage: PropTypes.string,
  source: PropTypes.object
};

export default Head;
