import React, { Fragment } from "react";
import LinkNode from "components/Nav/LinkNode";
import { useRouter } from "next/router";
import { NAV_BAR_STYLES } from "utils/constants/ui";
import classnames from "classnames";
import AuthButton from "components/Nav/AuthButton";
import { useRouteConfig } from "utils/hooks/navigation";
import { isServer } from "utils/view";
import { useAuthLoading } from "utils/selectors";
import { useNavContext } from "utils/context/nav";

const LoadingNodes = (count) =>
  new Array(count).fill("").map((nodeIx) => (
    <li key={nodeIx}>
      <div className="navLink__label color-text--primary db br2 pa2 shimmer h2 w3 ml3" />
    </li>
  ));

const DesktopNav = ({ loading }) => {
  const authLoading = useAuthLoading();
  const { route } = useRouter();
  const { values, setValues } = useNavContext();

  const routeConfig = useRouteConfig();

  const navNodes = routeConfig.map((config, routeIndex) => {
    /**
     * Debug testing override for blue active bar on nav node
     */
    const isActive = `/${config.sectionId}` === route;
    // const isActive = config.sectionId === route;

    const nodeProps = {
      href: config.href,
      zone: config.zone,
      hoc: config.hoc,
      label: config.label,
      highlight: config.style.highlight,
      subnav: config.subnav,
      sectionId: config.sectionId,
      callout: config.callout,
      IconComp: config.icon,
      routeProps: config,
      linkProps: {
        className: classnames("", {
          "fw6": isActive,
          "navLink__label color-text--primary link db br2 f5 pa2 fw5": routeIndex
        })
      },
      isActive
    };

    return <LinkNode key={routeIndex} {...nodeProps} />;
  });

  return (
    <nav
      key="desktopNav"
      className="desktopNav dn db-l fixed top-0 left-0 right-0 bg-white z-6"
    >
      <ul
        style={NAV_BAR_STYLES}
        className="pa0 ma0 flex flex-row items-center hairline-1 bb w-100 list"
      >
        <div
          className="flex flex-row justify-between items-center h-100"
          onMouseLeave={() => {
            setValues({
              ...values,
              close: true,
              activeSection: null
            });
          }}
        >
          {authLoading || loading || isServer() ? (
            <LoadingNodes count={routeConfig.length} />
          ) : (
            <Fragment>
              {navNodes}
              <div
                key="desktopAuth"
                className="dib h-100 flex items-center bb bw1 b--transparent absolute right-1"
              >
                <AuthButton />
              </div>
            </Fragment>
          )}
        </div>
      </ul>
    </nav>
  );
};

export default DesktopNav;
