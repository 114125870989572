import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getInitialCtxApplicationId } from "utils/mapStateToProps";
import { useRouter } from "next/router";
import { interpolateRouteWithParams } from "utils/route";
import PATHS from "utils/paths";
import { HUB_APP_SUB_SECTION } from "utils/constants/navigation";
import { useCtxReferences } from "utils/selectors";
import trimStart from "lodash/trimStart";

export const redirectDefaultHubRoute = () => {
  const router = useRouter();
  const { redirectTo } = router.query;

  const application = useSelector((state) => {
    const appId = getInitialCtxApplicationId(state);
    let appMatch;
    if (appId) {
      appMatch = state
        .getIn(["application", "data"])
        .find((app) => app.get("id") === appId);
    }

    return appMatch ? appMatch.toJS() : null;
  });

  useEffect(() => {
    if (application) {
      const destination = `${interpolateRouteWithParams(
        PATHS.HUB_APPLICATIONS_SHOW,
        {
          application
        }
      )}/${redirectTo ? trimStart(redirectTo, "/") : HUB_APP_SUB_SECTION.HOME}`;

      if (router.pathname !== destination) {
        router.replace(destination);
      }
    } else {
      router.replace(PATHS.LOGIN);
    }
  }, [application, router.pathname]);
};

export const useHubActiveSection = () => {
  const { query } = useRouter();
  return query.param && query.param.length ? query.param[0] : "";
};

export const useAppHubRoute = (path, models) => {
  const { application } = useCtxReferences();
  return application
    ? interpolateRouteWithParams(path || PATHS.HUB_APPLICATIONS_SHOW, {
        application,
        ...models
      })
    : "";
};

export const usePrevPathFragment = (count = 1) => {
  const { asPath } = useRouter();
  const parts = asPath.split("/");
  const previousPath = parts.slice(0, parts.length - count).join("/");

  return previousPath;
};
