import C from "constants/organizationCampaign";
import ActionHelpers from "./helpers";

export function setLoading() {
  return {
    type: C.UPDATE_ORGANIZATION_CAMPAIGN_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setOrganizationCampaigns = (payload) => {
  return {
    type: C.SET_ORGANIZATION_CAMPAIGNS,
    payload
  };
};

export const replaceOrganizationCampaigns = (payload) => {
  return {
    type: C.REPLACE_ORGANIZATION_CAMPAIGNS,
    payload
  };
};

/**
 * resetOrganizationCampaignNotes
 */
export function resetOrganizationCampaignNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_ORGANIZATION_CAMPAIGN_STORE,
      payload
    });
  };
}

export function showOrganizationCampaignNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_ORGANIZATION_CAMPAIGN_STORE,
        message || `Success!`
      )
    );
  };
}
