import React from "react";
import { IoIosClose } from "@hacknug/react-icons/io";
import { ICON_PROPS } from "components/Form/fields/constants";
import Padder from "components/Padder";
import classnames from "classnames";
import PATHS from "utils/paths";
import AuthButton from "components/Nav/AuthButton";
import LinkNode from "components/Nav/LinkNode";
import { useRouteConfig } from "utils/hooks/navigation";
import { BUTTON_CLASS_CONTEXT } from "utils/constants/ui";
import { useNavContext } from "utils/context/nav";

const OpenMobileMenu = () => {
  const { values, setValues } = useNavContext();
  const routeConfig = useRouteConfig();
  const closeMenu = () => setValues({ ...values, open: false });
  const navNodes = routeConfig.map((config, key) => {
    const notDashboard = config.href !== PATHS.HUB;

    const nodeProps = {
      href: config.href,
      zone: config.zone,
      hoc: config.hoc,
      label: config.label,
      highlight: config.style.highlight,
      subnav: config.subnav,
      sectionId: config.sectionId,
      callout: config.callout,
      routeProps: config,
      linkProps: {
        className: classnames(
          "navLink__label color-text--primary link db f4 pa3 w-100 w-auto-l fw6",
          {
            "bb b--black-05": notDashboard
          }
        )
      },
      IconComp: config.icon,
      showAlways: true
    };

    return <LinkNode key={key} {...nodeProps} />;
  });

  return (
    <div className="h-100 dib w-100 fixed z-1 bg-white overflow-y-scroll">
      <div className="absolute top-0 right-0 pa2 pointer z-7">
        <IoIosClose {...ICON_PROPS} size={40} onClick={closeMenu} />
      </div>
      <div className="pa0 ma0">
        {navNodes}
        <div className="w-100 hairline-1 mt3" onClick={closeMenu}>
          <AuthButton
            key="mobileNav"
            customClasses={{ contextClass: BUTTON_CLASS_CONTEXT.SECONDARY }}
          />
        </div>
      </div>
      <Padder theme="visible" />
    </div>
  );
};

export default OpenMobileMenu;
