import C from "constants/attachment";
import { reduceModelActions } from "utils/action";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { prepareModelAssociations } from "utils/data";
import ActionHelpers from "./helpers";
import { setOrganizationAttachments } from "./organizationAttachment";
import { setUserAttachments } from "./userAttachment";
import { ACTION_TYPE } from "utils/constants/action";
import { apiResourcePath, API_PATHS } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_ATTACHMENT_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setAttachments = (payload) => {
  return {
    type: C.SET_ATTACHMENTS,
    payload
  };
};

export const removeAttachments = (payload) => {
  return {
    type: C.REMOVE_ATTACHMENTS,
    payload
  };
};

export const replaceAttachments = (payload) => {
  return {
    type: C.REPLACE_ATTACHMENTS,
    payload
  };
};

/**
 * resetAttachmentNotes
 */
export function resetAttachmentNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_ATTACHMENT_STORE,
      payload
    });
  };
}

export function showAttachmentNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_ATTACHMENT_STORE,
        message || `Success!`
      )
    );
  };
}

export const ATTACHMENT_MODELS = ["attachments"];

export const ATTACHMENT_ACTIONS_MAP = {
  setAttachments,
  setOrganizationAttachments,
  setUserAttachments
};

/**
 * createAttachment
 */
export function createAttachment(
  { resourceID, resourceUUID, resource, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_ATTACHMENT_STORE,
        "create",
        "attachment",
        "Create attachment"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/attachments`;

    return axios({
      method: "post",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const attachments = [responseData];

        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          attachments
        });

        const actions = reduceModelActions({
          keys: ATTACHMENT_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: ATTACHMENT_ACTIONS_MAP
        });

        actions.push(
          setTimeout(() => {
            dispatch(
              ActionHelpers.successNote(
                C.UPDATE_ATTACHMENT_STORE,
                "File created"
              )
            );
          }, 250)
        );

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_ATTACHMENT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * deleteAttachment
 */
export const deleteAttachment = (
  { resourceUUID, resource, uuid },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/attachments/${uuid}`;
    return axios({
      method: "delete",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        dispatch(
          batchActions([
            removeAttachments([responseData]),
            ActionHelpers.successNote(C.UPDATE_ATTACHMENT_STORE, "File deleted")
          ])
        );

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_ATTACHMENT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * updateAttachment
 */
export function updateAttachment(
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_ATTACHMENT_STORE,
        "update",
        "attachment",
        "Update attachment"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/attachments/${uuid}`;

    return axios({
      method: "put",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const actions = [setAttachments([responseData])];

        actions.push(
          setTimeout(() => {
            dispatch(
              ActionHelpers.successNote(
                C.UPDATE_ATTACHMENT_STORE,
                "File updated"
              )
            );
          }, 250)
        );

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_ATTACHMENT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * fetchAttachments
 */
export function fetchAttachments(
  { resourceUUID, resource, resourceID, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_ATTACHMENT_STORE,
        "update",
        "attachment",
        "Fetch attachments"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/attachments`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          attachments: responseData
        });

        const actions = reduceModelActions({
          keys: ATTACHMENT_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: ATTACHMENT_ACTIONS_MAP
        });

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_ATTACHMENT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

export const createAnonAttachment = async ({
  data,
  headers,
  setLoading,
  onError
}) => {
  setLoading && setLoading(true);

  try {
    const response = await axios({
      method: "post",
      url: API_PATHS.ATTACHMENTS_ANON,
      data,
      headers: {
        ...headers,
        ...authBearerToken()
      }
    });
    setLoading && setLoading(false);
    return response.data;
  } catch (error) {
    setLoading && setLoading(false);

    onError && onError(error);
    return error;
  }
};

export const fetchAnonAttachment = async ({ headers, setLoading, onError }) => {
  setLoading && setLoading(true);

  try {
    const response = await axios({
      method: "get",
      url: API_PATHS.ATTACHMENTS_ANON,
      headers: {
        ...headers,
        ...authBearerToken()
      }
    });
    setLoading && setLoading(false);
    return response.data;
  } catch (error) {
    setLoading && setLoading(false);

    onError && onError(error);
    return error;
  }
};

export const deleteAnonAttachment = async ({
  headers,
  setLoading,
  onError
}) => {
  setLoading && setLoading(true);

  try {
    const response = await axios({
      method: "delete",
      url: API_PATHS.ATTACHMENTS_ANON,
      headers: {
        ...headers,
        ...authBearerToken()
      }
    });
    setLoading && setLoading(false);
    return response.data;
  } catch (error) {
    setLoading && setLoading(false);

    onError && onError(error);
    return error;
  }
};
