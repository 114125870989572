import React from "react";
import Link from "next/link";
import { isZone, isZoneMatch } from "utils/nav";
import { getConfig } from "utils/env";
import { getMainAppPath } from "utils/route";

/**
 * zone (currently in a zone app)
 * - destination-zone - local nav in zone - requires Link
 * - destination-otherZone-or-main - nav to external zone main - requires anchor tag to absolute path - no back rewrites
 * main (the main app)
 * - destination-zone - nav to external zone - requires anchor tag for rewrites
 * - destination-main - nav local - requires Link
 */
const LINK_STYLE = { textDecoration: "none" };

const ZoneLink = ({
  zone,
  href,
  children,
  routeProps,
  linkProps,
  ...props
}) => {
  if (href) {
    const currentlyInZone = isZone();

    if (currentlyInZone) {
      const zoneMatch = isZoneMatch(zone);

      if (zoneMatch) {
        const strippedHref = href.replace(
          new RegExp(getConfig("BASE_PATH")),
          ""
        );

        return (
          <Link {...routeProps} href={strippedHref}>
            <a
              style={LINK_STYLE}
              id="zone-destination-zone"
              {...linkProps}
              {...props}
            >
              {children}
            </a>
          </Link>
        );
      } else {
        // Need to go back to the main app - no rewrites on local
        const toMainApp = getMainAppPath(href);

        return (
          <a
            style={LINK_STYLE}
            id="zone-destination-otherZone-or-main"
            href={toMainApp}
            {...linkProps}
            {...props}
          >
            {children}
          </a>
        );
      }
    } else {
      /**
       * When the route destination is zone && not currently in a zone
       * - then anchor tag to zone
       */
      if (zone) {
        return (
          <a
            style={LINK_STYLE}
            id="main-destination-zone"
            href={href}
            {...linkProps}
            {...props}
          >
            {children}
          </a>
        );
      } else {
        // Not in a zone and no going to a zone
        return (
          <Link href={href} {...routeProps}>
            <a
              style={LINK_STYLE}
              id="main-destination-main"
              {...linkProps}
              {...props}
            >
              {children}
            </a>
          </Link>
        );
      }
    }
  } else {
    return <div {...props}>{children}</div>;
  }
};

export default ZoneLink;
