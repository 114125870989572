import React from "react";

const ShippingRates = (props) => {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"navIcon--shippingRates"}</title>
      <g fillRule="nonzero" fill="none">
        <path
          d="M37.638 17.919l-1.656-3.839a3.79 3.79 0 00-3.5-2.295H29.81v-.979c0-.79-.64-1.467-1.468-1.467H9.677a.972.972 0 00-.979.978c0 .527.452.979.979.979h18.1v7.225c0 .903.716 1.618 1.62 1.618h6.51v4.554c0 .188-.151.338-.34.338h-1.203a3.62 3.62 0 00-3.124-1.806c-1.317 0-2.484.753-3.123 1.806H18.069a3.62 3.62 0 00-3.124-1.806c-1.317 0-2.483.753-3.123 1.806H8.36a.972.972 0 00-.979.979c0 .564.452.978.979.978h2.973a3.61 3.61 0 003.612 3.462 3.61 3.61 0 003.613-3.462h9.07a3.61 3.61 0 003.612 3.462 3.61 3.61 0 003.613-3.462h.79a2.305 2.305 0 002.296-2.295v-5.269a4.29 4.29 0 00-.301-1.505zM14.945 28.493a1.67 1.67 0 01-1.655-1.655 1.67 1.67 0 011.655-1.656 1.67 1.67 0 011.656 1.656 1.67 1.67 0 01-1.656 1.655zm16.295 0a1.67 1.67 0 01-1.656-1.655 1.67 1.67 0 011.656-1.656 1.67 1.67 0 011.656 1.656 1.67 1.67 0 01-1.656 1.655zM29.772 13.78h2.672c.715 0 1.393.414 1.656 1.092l1.43 3.311h-5.758V13.78z"
          fill="#5D8BFF"
        />
        <path
          d="M6.855 15.397h6.924a.972.972 0 00.978-.978.972.972 0 00-.978-.978H6.855a.972.972 0 00-.979.978c0 .527.452.978.979.978zM5.01 19.16h6.925a.972.972 0 00.978-.978.972.972 0 00-.978-.978H5.01a.972.972 0 00-.979.978c0 .527.452.979.979.979zM11.107 21.945a.972.972 0 00-.978-.978H3.167a.972.972 0 00-.979.978c0 .565.452.979.979.979h6.924c.564.037 1.016-.414 1.016-.979z"
          fill="#A6C0FF"
        />
      </g>
    </svg>
  );
};

export default ShippingRates;
