import C from "constants/userEditableContent";

export function setLoading() {
  return {
    type: C.UPDATE_USER_EDITABLE_CONTENT_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setUserEditableContents = (payload) => {
  return {
    type: C.SET_USER_EDITABLE_CONTENTS,
    payload
  };
};

export const replaceUserEditableContents = (payload) => {
  return {
    type: C.REPLACE_USER_EDITABLE_CONTENTS,
    payload
  };
};
