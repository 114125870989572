import { getConfig } from "utils/env";

const LICENSED = "licensed";
const METERED = "metered";

export const BILLING_PLANS = {
  LICENSED,
  METERED
};

export const ENABLED_ENTITLEMENTS_CONFIG = {
  enabled: true,
  upgrade: {
    scope: "paid"
  }
};

const PROD_CONFIG = {
  api_key: getConfig("PRICE_BLOCS_PUB_KEY"),
  prices: [
    // ============================
    // Starter
    // prod_I6erCiYzlAvFxp
    // https://dashboard.stripe.com/products/prod_I6erCiYzlAvFxp
    "price_1HWRXpAjcnPhf83WpwOlrfpy", // (0 per month)
    "price_1HWRXpAjcnPhf83WmP9HwFtp", // (0 per year)
    // ============================
    // Pro
    // prod_I6eslRwCKS0ls4
    // https://dashboard.stripe.com/products/prod_I6eslRwCKS0ls4
    "price_1J5JQIAjcnPhf83WNhd6xl9Q", // (49 per month)
    "price_1HWRZBAjcnPhf83W19OZcVQL", // (528 per year)
    // ============================
    // Growth
    // prod_KyfOEmuf0zU1SI
    // https://dashboard.stripe.com/products/prod_KyfOEmuf0zU1SI
    "price_1KIi3gAjcnPhf83WlJkuJ8oI", // (99 per month)
    "price_1KIi3gAjcnPhf83WfS4TFh15" // (1008 per year),
    // GBS
    // "price_1KIi7IAjcnPhf83WKMSZWpme", // Monthly 1%
    // "price_1KIi6wAjcnPhf83WphYt79aV" // Yealy 1%
    // ============================
    // Established
    // prod_KyfPo91bMT1m2o
  ],
  entitlements: ENABLED_ENTITLEMENTS_CONFIG,
  features: {
    enabled: true
  }
};

const DEV_CONFIG = {
  api_key: getConfig("PRICE_BLOCS_PUB_KEY"),
  prices: [
    // ============================
    // Starter
    // prod_I6fhTbPWbyRlqu
    // https://dashboard.stripe.com/test/products/prod_I6fhTbPWbyRlqu
    "price_1HWSM5AjcnPhf83WvvRLGMiT", // (0 per month)
    "price_1HWSM6AjcnPhf83WAazk43gk", // (0 per year)
    // ============================
    // Pro
    // prod_I6fhoMDDuG2cLt
    // https://dashboard.stripe.com/test/products/prod_I6fhoMDDuG2cLt
    "price_1IqRYAAjcnPhf83WTkqdp2sr", // (50 per month)
    "price_1JrnVPAjcnPhf83Wa3AGdxMU", // (480 per year) 50 * 0.8
    // ============================
    // Growth
    // prod_KyZR0q8dO9YwLO

    "price_1KIcJ6AjcnPhf83WP3nYOauQ", // (99 per month)
    "price_1KIe1KAjcnPhf83Wiv0B0Jv8" // (1008 per year),
    // ============================
    // Established
    // prod_KyZUA0p2WCafej
  ],
  config: {
    /**
     * Turn off: not developing react-priceblocs-js library
     */
    fetch: {
      on_mount: false,
      on_customer_change: false
    }
    /**
     * Turn on: active developing react-priceblocs-js library
     */
    // fetch: {
    //   on_mount: true,
    //   on_customer_change: true
    // }
  },
  entitlements: ENABLED_ENTITLEMENTS_CONFIG,
  features: {
    enabled: true
  }
};

export const BILLING_PLAN_CONFIG =
  process.env.NODE_ENV === "production" ? PROD_CONFIG : DEV_CONFIG;
