import C from "constants/userWebhook";

export function setLoading() {
  return {
    type: C.UPDATE_USER_WEBHOOK_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setUserWebhooks = (payload) => {
  return {
    type: C.SET_USER_WEBHOOKS,
    payload
  };
};

export const replaceUserWebhooks = (payload) => {
  return {
    type: C.REPLACE_USER_WEBHOOKS,
    payload
  };
};
