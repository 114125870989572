import C from "constants/userCampaign";

export function setLoading() {
  return {
    type: C.UPDATE_USER_CAMPAIGN_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setUserCampaigns = (payload) => {
  return {
    type: C.SET_USER_CAMPAIGNS,
    payload
  };
};

export const replaceUserCampaigns = (payload) => {
  return {
    type: C.REPLACE_USER_CAMPAIGNS,
    payload
  };
};
