import startCase from "lodash/startCase";

export const flattenConfigRoutes = (config, parent) => {
  return Object.keys(config).reduce((memo, key) => {
    if (typeof config[key] === "object") {
      if (config[key].as) {
        const result = {
          label: startCase(key),
          as: config[key].as,
          path: config[key]
        };
        if (parent) {
          result.parent = startCase(parent);
        }
        memo.push(result);
      } else {
        memo = memo.concat(flattenConfigRoutes(config[key], key));
      }
    }
    return memo;
  }, []);
};

const formatConfig = (config) => ({
  label: config.label,
  parent: config.parent,
  ...config.path
});

export const getNextAndPreviousRoutes = ({ config, asPath }) => {
  const flatRoutes = flattenConfigRoutes(config);
  const targetIndex = flatRoutes.findIndex(({ as }) => as === asPath);
  const prev = flatRoutes[targetIndex - 1];
  const next = flatRoutes[targetIndex + 1];

  return {
    prev: prev ? formatConfig(prev) : null,
    next: next ? formatConfig(next) : null
  };
};

/**
 * We dont use getConfig("BASE_PATH") like in zoned apps here because we have no value for it in main app
 * We have them here for consistency accross apps
 */
export const isZone = () => {
  return false;
};

export const isZoneMatch = () => {
  return false;
};
