import React from "react";
import ZoneLink from "components/ZoneLink";
import BrandIcon from "components/Icons/brand";
import { IoIosMenu } from "@hacknug/react-icons/io";
import PATHS from "utils/paths";
import { NAV_BAR_STYLES } from "utils/constants/ui";
import { ICON_PROPS } from "components/Form/fields/constants";
import { useIsAuthed } from "utils/selectors";
import { useNavContext } from "utils/context/nav";
import { useAppHubRoute } from "utils/hooks/hub";
import { HUB_APP_SUB_SECTION } from "utils/constants/navigation";
import { getJWT } from "utils/auth";

const ClosedMobileMenu = () => {
  const { values, setValues } = useNavContext();
  const isAuthed = useIsAuthed();
  const hubPath = useAppHubRoute();

  const logoHref = isAuthed
    ? `${hubPath}/${HUB_APP_SUB_SECTION.HOME}`
    : getJWT()
    ? PATHS.HUB
    : PATHS.HOME;

  return (
    <ul
      style={NAV_BAR_STYLES}
      className="ma0 flex flex-items justify-between pa0 bb hairline-1 ba"
    >
      <li className="dib o-0 flex pa2 w-third" />
      <li className="dib flex items-center justify-center pa2 w-third">
        <ZoneLink href={logoHref}>
          <div onClick={() => setValues({ ...values, open: false })}>
            <BrandIcon />
          </div>
        </ZoneLink>
      </li>
      <li className="dib pa2 w-third flex items-center justify-end">
        <IoIosMenu
          className="pointer"
          {...ICON_PROPS}
          size={40}
          onClick={() => setValues({ ...values, open: true })}
        />
      </li>
    </ul>
  );
};

export default ClosedMobileMenu;
