import C from "constants/userContactlessConfig";
import ActionHelpers from "./helpers";

export function setLoading() {
  return {
    type: C.UPDATE_USER_CONTACTLESS_CONFIG_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setUserContactlessConfigs = (payload) => {
  return {
    type: C.SET_USER_CONTACTLESS_CONFIGS,
    payload
  };
};

export const replaceUserContactlessConfigs = (payload) => {
  return {
    type: C.REPLACE_USER_CONTACTLESS_CONFIGS,
    payload
  };
};

/**
 * resetUserContactlessConfigNotes
 */
export function resetUserContactlessConfigNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_USER_CONTACTLESS_CONFIG_STORE,
      payload
    });
  };
}

export function showUserContactlessConfigNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_USER_CONTACTLESS_CONFIG_STORE,
        message || `Success!`
      )
    );
  };
}
