import React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"navIcon--links"}</title>
      <path
        d="M6.76 23.34c-2.835 2.836-2.923 7.367-.195 10.095 2.728 2.728 7.26 2.64 10.095-.196l5.717-5.717c2.836-2.835 2.924-7.367.196-10.095a1.159 1.159 0 00-1.15-.299 1.254 1.254 0 00-.864.865c-.118.417-.003.856.299 1.15 1.842 1.842 1.784 4.814-.13 6.73l-5.718 5.716c-1.915 1.916-4.888 1.973-6.73.13-1.842-1.841-1.785-4.814.13-6.729l5.432-5.431c.313-.305.445-.75.344-1.162a1.129 1.129 0 00-.832-.832 1.224 1.224 0 00-1.162.344L6.76 23.34zm10.863-10.862c-2.836 2.835-2.924 7.367-.196 10.095.293.302.733.416 1.15.299.417-.118.747-.448.864-.865a1.158 1.158 0 00-.299-1.15c-1.842-1.842-1.784-4.814.13-6.73l5.718-5.716c1.915-1.916 4.888-1.973 6.73-.13 1.842 1.841 1.785 4.814-.13 6.729l-5.432 5.431a1.223 1.223 0 00-.344 1.162c.102.413.42.73.832.832.413.1.857-.03 1.162-.344l5.431-5.431c2.836-2.836 2.924-7.367.196-10.095-2.728-2.728-7.26-2.64-10.095.196l-5.717 5.717z"
        fill="#5D8BFF"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgComponent;
