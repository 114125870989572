import C from "constants/insight";
import { reduceModelActions } from "utils/action";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { prepareModelAssociations } from "utils/data";
import ActionHelpers from "./helpers";
import { ACTION_TYPE } from "utils/constants/action";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_INSIGHT_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setInsights = (payload) => {
  return {
    type: C.SET_INSIGHTS,
    payload
  };
};

export const setUserInsights = (payload) => {
  return {
    type: "mock",
    payload
  };
};

export const setOrganizationInsights = (payload) => {
  return {
    type: "mock",
    payload
  };
};

/**
 * resetInsightNotes
 */
export function resetInsightNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_INSIGHT_STORE,
      payload
    });
  };
}

export const INSIGHT_MODELS = ["insights"];

export const INSIGHT_ACTIONS_MAP = {
  setInsights,
  setUserInsights,
  setOrganizationInsights
};

/**
 * fetchLatestInsights
 */
export function fetchLatestInsights(
  { resourceUUID, resource, resourceID, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_INSIGHT_STORE,
        "update",
        "insight",
        "Fetch insights"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/insights/latest`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      params: data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          insights: responseData
        });

        const actions = reduceModelActions({
          keys: INSIGHT_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: INSIGHT_ACTIONS_MAP
        });

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_INSIGHT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}
