import React from "react";

const Webflow = (props) => {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"webflow_icon"}</title>
      <g fillRule="nonzero" fill="none">
        <path
          d="M38.4 20.2c0 10.01-8.19 18.2-18.2 18.2S2 30.21 2 20.2 10.19 2 20.2 2s18.2 8.19 18.2 18.2z"
          fill="#4353FF"
        />
        <path
          d="M25.751 17.861s-1.574 4.933-1.693 5.351c-.045-.418-1.192-9.282-1.192-9.282-2.684 0-4.113 1.902-4.868 3.931 0 0-1.911 4.95-2.066 5.37-.009-.392-.291-5.315-.291-5.315-.164-2.475-2.42-3.977-4.25-3.977l2.202 13.432c2.812-.01 4.323-1.911 5.115-3.931 0 0 1.683-4.368 1.756-4.569.018.192 1.21 8.5 1.21 8.5 2.812 0 4.332-1.784 5.15-3.731l3.932-9.71c-2.785 0-4.25 1.902-5.005 3.931z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
};

export default Webflow;
