import React from "react";

const Tutorial = (props) => {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"navIcon--tutorial"}</title>
      <path
        d="M37.38 14.314L20.24 7.068a.85.85 0 00-.667 0L2.43 14.314a.863.863 0 00-.004 1.591l6.432 2.72a.907.907 0 00-.064.324v8.854a.86.86 0 00.529.796l10.247 4.332a.833.833 0 00.667 0l10.246-4.332a.863.863 0 00.525-.796v-8.85a.808.808 0 00-.064-.324l2.829-1.198v10.623c0 .476.387.865.86.865s.86-.39.86-.865V17.008c0-.1-.022-.195-.052-.286l1.935-.817a.863.863 0 00.525-.795.853.853 0 00-.52-.796zM19.904 8.802L34.83 15.11l-14.926 6.312L4.98 15.11l14.925-6.308zm9.392 18.43l-9.392 3.97-9.387-3.97V19.33l9.056 3.83c.108.044.22.07.336.07a.818.818 0 00.335-.07l9.056-3.83v7.904h-.004z"
        fill="#5D8BFF"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default Tutorial;
