import C from "constants/userWorkflow";

export function setLoading() {
  return {
    type: C.UPDATE_USER_WORKFLOW_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setUserWorkflows = (payload) => {
  return {
    type: C.SET_USER_WORKFLOWS,
    payload
  };
};

export const replaceUserWorkflows = (payload) => {
  return {
    type: C.REPLACE_USER_WORKFLOWS,
    payload
  };
};
