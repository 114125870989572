import css from "styled-jsx/css";

export const ctaStyles = css`
  .cta__rocket--clip::after {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: url("/assets/cta__rocket--clip.png");
    background-size: contain;
    background-position-x: right;
    background-position-y: bottom;
    background-repeat: no-repeat;
  }

  .cta__rocket--clip80::after {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: url("/assets/cta__rocket--clip80.png");
    background-size: contain;
    background-position-x: right;
    background-position-y: bottom;
    background-repeat: no-repeat;
  }

  @media (max-width: 30em) {
    .cta__rocket--clip::after {
      background-position-x: right;
      background-position-y: bottom;
    }
  }

  .cta__rocket--clipBottom::after {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: url("/assets/cta__rocket--clip.png");
    background-size: contain;
    background-position-x: right;
    background-position-y: bottom;
    background-repeat: no-repeat;
  }

  .cta__cloud--clip::after {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: url("/assets/bg__cloudMask_0.png");
    background-size: cover;
    background-position-x: right;
    background-position-y: bottom;
    background-repeat: no-repeat;
  }
`;
