import React from "react";

const Channels = (props) => {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"navIcon--channels"}</title>
      <g fill="none" fillRule="evenodd">
        <g transform="rotate(90 8.116 30.08)">
          <path
            d="M7.203 0c3.978 0 7.202 3.198 7.202 7.143 0 3.455-2.474 6.338-5.761 7V20H5.761v-5.857C2.476 13.48 0 10.599 0 7.143 0 3.198 3.225 0 7.203 0z"
            fill="#A6C0FF"
          />
          <ellipse fill="#FFF" cx={7.203} cy={7.143} rx={4.322} ry={4.286} />
        </g>
        <g transform="rotate(-146 11.643 17.915)">
          <path
            d="M7.61.18c3.978 0 7.203 3.197 7.203 7.142 0 3.456-2.474 6.338-5.762 7v5.857H6.17v-5.857c-3.288-.661-5.763-3.544-5.763-7C.407 3.377 3.632.18 7.61.18z"
            fill="#A6C0FF"
          />
          <ellipse fill="#FFF" cx={7.61} cy={7.322} rx={4.322} ry={4.286} />
        </g>
        <g transform="rotate(-30 19.65 -15.354)">
          <path
            d="M7.203 0c3.978 0 7.202 3.198 7.202 7.143 0 3.455-2.474 6.338-5.761 7V20H5.761v-5.857C2.476 13.48 0 10.599 0 7.143 0 3.198 3.225 0 7.203 0z"
            fill="#5D8BFF"
          />
          <ellipse fill="#FFF" cx={7.203} cy={7.143} rx={4.322} ry={4.286} />
        </g>
      </g>
    </svg>
  );
};

export default Channels;
