import * as React from "react";

const Creators = (props) => {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"navIcon--creator"}</title>
      <g fill="#5D8BFF" fillRule="nonzero">
        <path d="M20.132 13.067l6.282 6.23a9.64 9.64 0 01-.324.344c-3.079 3.08-6.172 6.159-9.279 9.238-.236.235-.524.41-.841.513a406.468 406.468 0 01-6.6 1.94 1.57 1.57 0 01-.78.041.821.821 0 01-.555-1.026c.685-2.395 1.381-4.772 2.09-7.129a1.15 1.15 0 01.297-.457c3.202-3.226 6.405-6.435 9.607-9.628a.313.313 0 01.103-.066zm-4.768 14.637c-.185-.98-.36-1.92-.549-2.849a.41.41 0 00-.23-.277c-.94-.21-1.89-.4-2.854-.595-.35 1.19-.652 2.34-1.027 3.464-.132.41.005.858.344 1.124a.75.75 0 00.944.139l3.372-1.006zM28.318 17.013l-5.933-5.855c.878-.935 1.699-1.91 2.633-2.751a1.904 1.904 0 012.5.128 75.414 75.414 0 013.263 3.27c.705.78.705 1.969 0 2.75a4.865 4.865 0 01-.344.37l-2.12 2.088z" />
      </g>
    </svg>
  );
};

export default Creators;
