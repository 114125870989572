import React from "react";
import classnames from "classnames";
import ClosedMobileMenu from "./ClosedMobileMenu";
import OpenMobileMenu from "./OpenMobileMenu";
import { useNavContext } from "utils/context/nav";

const MobileNav = () => {
  const {
    values: { open }
  } = useNavContext();
  const navClasses = classnames("mobileNav db dn-l bg-white z-6", {
    "absolute h-100 w-100 ": open,
    "fixed top-0 left-0 right-0": !open
  });

  const content = open ? <OpenMobileMenu /> : <ClosedMobileMenu />;

  return (
    <nav key="mobileNav" className={navClasses}>
      {content}
    </nav>
  );
};

export default MobileNav;
