import C from "constants/userLegalContent";

export function setLoading() {
  return {
    type: C.UPDATE_USER_LEGAL_CONTENT_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setUserLegalContents = (payload) => {
  return {
    type: C.SET_USER_LEGAL_CONTENTS,
    payload
  };
};

export const replaceUserLegalContents = (payload) => {
  return {
    type: C.REPLACE_USER_LEGAL_CONTENTS,
    payload
  };
};
