import React from "react";

function SvgComponent(props) {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
      <title>{"navIcon--profile"}</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M10 33.411v-5.704s.738-2.816 2.908-2.816h15.074s2.745.004 2.745 2.772v5.82"
          stroke="#5D8BFF"
          strokeWidth={2.64}
        />
        <g transform="translate(12.4 7.327)">
          <ellipse fill="#5D8BFF" cx={7.964} cy={7.336} rx={7.434} ry={7.336} />
          <ellipse fill="#FFF" cx={7.964} cy={7.336} rx={5.002} ry={4.936} />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
