import axios from "axios";
import { CDN_PLACEHOLDER_IMAGE } from "components/Image";
import { authBearerToken } from "./auth";
import { FLAG_STATUS } from "./constants/capability";
import { getConfig } from "./env";

const getAssetHeaders = (headers) => ({
  ...headers,
  ...authBearerToken()
});

export const signAssetPick = async (headers) => {
  try {
    const REQUEST_URL = `${getConfig("API_ROOT")}/v1/assets/pick/sign`;
    const { data } = await axios({
      method: "get",
      url: REQUEST_URL,
      headers: getAssetHeaders(headers)
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const signAssetRead = async ({ handle }, headers) => {
  try {
    const REQUEST_URL = `${getConfig("API_ROOT")}/v1/assets/read/sign`;
    const { data } = await axios({
      method: "get",
      url: REQUEST_URL,
      params: {
        handle
      },
      headers: getAssetHeaders(headers)
    });

    return data;
  } catch (error) {
    return error;
  }
};

/**
 * ====================================
 * Anonymous asset actions
 * ====================================
 */
export const anonSignAssetPick = async (headers) => {
  try {
    const REQUEST_URL = `${getConfig("API_ROOT")}/v1/assets/pick/sign/anon`;
    const { data } = await axios({
      method: "get",
      url: REQUEST_URL,
      headers: getAssetHeaders(headers)
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const isCDN = (images) => {
  const sources = Array.isArray(images) ? images : [images];
  let sourcesAreCDN = false;

  for (let sourceIx = 0; sourceIx < sources.length; sourceIx++) {
    const imageSrc = sources[sourceIx];
    sourcesAreCDN = /cdn.filestackcontent.com/.test(imageSrc);
    if (sourcesAreCDN) {
      break;
    }
  }

  return sourcesAreCDN;
};

export const canRenderImages = (images, env = process.env.NODE_ENV) => {
  const result = {
    canRender: false,
    hasCDNImages: false,
    fallback: null
  };
  const sources = Array.isArray(images) ? images : images ? [images] : [];
  result.canRender = sources.length > 0;
  result.hasCDNImages = result.canRender && isCDN(sources);
  /**
   * If can render and is local
   * Then check if isCDN
   * If CDN then dont render
   */
  const renderEnabled =
    getConfig("CDN_RENDER_OVERRIDE") === FLAG_STATUS.ENABLED;
  if (result.canRender && env === "development" && !renderEnabled) {
    result.canRender = !result.hasCDNImages;
    result.fallback = CDN_PLACEHOLDER_IMAGE;
  }

  return result;
};

export const reduceImages = (images) =>
  images.reduce((memo, image) => {
    if (typeof image === "string") {
      memo.push(image);
    } else if (image.src) {
      memo.push(image.src);
    }
    return memo;
  }, []);
