import React, { Component } from "react";
import assign from "lodash/assign";
import PropTypes from "prop-types";
import cx from "classnames";
import LoadingSpinner from "components/LoadingSpinner";
import {
  BUTTON_HOVER_COLORS,
  ICON_BUTTON_CLASSES,
  ICON_PROPS
} from "components/Form/fields/constants";
import { TA_MAN, BUTTON_TAGS } from "utils/constants/ui";
import {
  IoIosArrowUp,
  IoIosArrowDown,
  IoIosArrowForward,
  IoIosAdd,
  IoIosRemove,
  IoIosColorWand,
  IoIosList,
  IoMdCheckmark,
  IoMdCheckmarkCircle,
  IoIosBookmark,
  IoIosStar,
  IoIosSave,
  IoIosRefresh,
  IoMdCreate,
  IoMdClose,
  IoIosMove,
  IoMdList,
  IoIosRemoveCircleOutline,
  IoIosInformationCircleOutline,
  IoIosMore,
  IoMdOpen
} from "@hacknug/react-icons/io";

const ICONS = {
  up: IoIosArrowUp,
  down: IoIosArrowDown,
  closed: IoIosArrowDown,
  right: IoIosArrowForward,
  add: IoIosAdd,
  remove: IoIosRemove,
  edit: IoMdCreate,
  cancel: IoMdClose,
  close: IoMdClose,
  trash: IoIosRemoveCircleOutline,
  refresh: IoIosRefresh,
  more: IoIosMore,
  open: IoMdOpen,
  save: IoIosSave,
  star: IoIosStar,
  bookmark: IoIosBookmark,
  tick: IoMdCheckmark,
  checkmark: IoMdCheckmarkCircle,
  suggestion: IoIosList,
  magic: IoIosColorWand,
  list: IoMdList,
  info: IoIosInformationCircleOutline,
  move: IoIosMove
};

export default class IconButton extends Component {
  constructor() {
    super();
    this.state = {
      hover: false
    };
  }

  hover(hover) {
    return () => {
      this.setState({ hover });
    };
  }

  render() {
    const {
      disabled,
      active,
      onClick,
      iconType,
      iconProps,
      colors,
      customClasses,
      customStyles,
      loading
    } = this.props;
    const IconComp = ICONS[iconType];
    if (!IconComp) return null;
    const { hover } = this.state;

    const icProps = assign({}, ICON_PROPS, iconProps);
    const classes = assign({}, ICON_BUTTON_CLASSES, customClasses);
    const baseStyle = disabled ? { cursor: "not-allowed" } : {};
    const styles = assign({ outline: "none" }, baseStyle, customStyles);

    const buttonProps = {
      type: BUTTON_TAGS.BUTTON,
      disabled,
      className: cx(`${classes.button} ${TA_MAN}`, {
        "o-30": disabled
      }),
      style: styles
    };

    if (Boolean(onClick) && !loading && !disabled) {
      buttonProps.onClick = onClick;
    }

    icProps.color = colors.inactive;
    if (active) {
      if (hover) {
        icProps.color = colors.activeHover;
      } else {
        icProps.color = colors.active;
      }
    } else if (hover) {
      icProps.color = colors.hover;
    }
    const hoverMgmtProps = {
      onMouseEnter: this.hover(true),
      onMouseLeave: this.hover(false)
    };

    return (
      <div {...hoverMgmtProps}>
        {loading ? (
          <LoadingSpinner customClasses={{ ring: "blue" }} />
        ) : (
          <button {...buttonProps}>
            <IconComp {...icProps} />
          </button>
        )}
      </div>
    );
  }
}

IconButton.propTypes = {
  onClick: PropTypes.func,
  iconProps: PropTypes.shape({
    size: PropTypes.number,
    color: PropTypes.string
  }),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  customClasses: PropTypes.object,
  customStyles: PropTypes.object,
  iconType: PropTypes.string,
  colors: PropTypes.object
};

IconButton.defaultProps = {
  disabled: false,
  active: false,
  colors: BUTTON_HOVER_COLORS
};
