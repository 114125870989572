import C from "constants/shippingRate";
import ActionHelpers from "./helpers";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { pluralizeCopy } from "utils/copy";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_SHIPPING_RATE_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setShippingRates = (payload) => {
  return {
    type: C.SET_SHIPPING_RATES,
    payload
  };
};

export const replaceShippingRates = (payload) => {
  return {
    type: C.REPLACE_SHIPPING_RATES,
    payload
  };
};

export const removeShippingRates = (payload) => {
  return {
    type: C.REMOVE_SHIPPING_RATES,
    payload
  };
};

/**
 * resetShippingRateNotes
 */
export function resetShippingRateNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_SHIPPING_RATE_STORE,
      payload
    });
  };
}

export function showShippingRateNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_SHIPPING_RATE_STORE,
        message || `Success!`
      )
    );
  };
}

/**
 * createMerchantAccountShippingRates
 */
export function createMerchantAccountShippingRates(
  { resourceUUID, resource, uuid, data: requestData },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_SHIPPING_RATE_STORE,
        "get",
        "app",
        "Create merchant account shippingRate"
      )
    );

    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/shipping-rates`;

    return axios({
      method: "post",
      url: REQUEST_URL,
      data: requestData,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const { shippingRates } = data;
        const actions = [
          ActionHelpers.clearLoader(C.UPDATE_SHIPPING_RATE_STORE)
        ];
        if (shippingRates.length) {
          actions.push(
            ActionHelpers.successNote(
              C.UPDATE_SHIPPING_RATE_STORE,
              `Created ${pluralizeCopy("shipping rate", shippingRates.length)}`
            )
          );
          actions.push(setShippingRates(shippingRates));
        }

        dispatch(batchActions(actions));
        callback(null, { shippingRates });
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_SHIPPING_RATE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * updateMerchantAccountShippingRates
 */
export const updateMerchantAccountShippingRates = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/merchant-accounts/${uuid}/shipping-rates`;

    return axios({
      method: "put",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: { shippingRates } }) => {
        if (shippingRates.length > 0) {
          dispatch(batchActions([setShippingRates(shippingRates)]));
        }
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_SHIPPING_RATE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * fetchMerchantAccountShippingRates
 */
export function fetchMerchantAccountShippingRates(
  { resourceUUID, resource, uuid, test },
  callback = () => {}
) {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID,
      test
    })}/merchant-accounts/${uuid}/shipping-rates`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data: { shippingRates } }) => {
        const actions = [
          ActionHelpers.clearLoader(C.UPDATE_SHIPPING_RATE_STORE)
        ];
        if (Array.isArray(shippingRates) && shippingRates.length) {
          actions.push(setShippingRates(shippingRates));
        }

        dispatch(batchActions(actions));
        callback();
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_SHIPPING_RATE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}
