import React from "react";

const Subscriptions = (props) => {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"navIcon--subscriptions"}</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M20.924 29.3v-1.887c1.11-.22 1.968-.736 2.571-1.546.603-.81.905-1.78.905-2.913 0-.818-.226-1.526-.679-2.123-.452-.598-1.67-1.345-3.654-2.241-1.016-.456-1.524-1.03-1.524-1.723 0-.55.19-.998.571-1.344.381-.346.857-.52 1.429-.52.905 0 1.69.41 2.357 1.228l1.048-1.51c-.778-.96-1.786-1.502-3.024-1.628V11.3h-1.405v1.77c-1.032.188-1.841.644-2.429 1.368-.587.723-.88 1.62-.88 2.689 0 1.463.912 2.619 2.738 3.468.952.44 1.635.759 2.047.955.413.197.69.429.834.696.142.268.214.574.214.92 0 1.542-.746 2.312-2.238 2.312-1.127 0-2.08-.747-2.857-2.24L15.4 24.7c.952 1.683 2.325 2.618 4.119 2.807V29.3h1.405z"
          fill="#5D8BFF"
          fillRule="nonzero"
        />
        <path
          d="M20.056 35.091c8.204.051 14.813-6.701 14.762-15.082a15.486 15.486 0 00-4.151-10.48c-2.724-2.925-6.559-4.762-10.796-4.788-8.204-.051-14.813 6.701-14.762 15.082a15.486 15.486 0 004.106 10.43"
          stroke="#5D8BFF"
          strokeWidth={2}
        />
        <path
          d="M9.988 31.537l1.762-8.005c.086-.457-.17-.945-.578-1.109-.41-.163-.836.069-.931.507l-1.573 7.202-6.727-2.685c-.425-.155-.835.069-.931.507-.096.438.159.927.578 1.109l7.469 2.98c.425.156.835-.068.931-.506z"
          fill="#5D8BFF"
        />
      </g>
    </svg>
  );
};

export default Subscriptions;
