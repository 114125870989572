import React from "react";

function SvgComponent(props) {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
      <title>{"navIcon--billing"}</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M32.84 2.176H7.364a.977.977 0 00-.968.968v33.968c0 .729.591 1.32 1.32 1.32h24.816a1.32 1.32 0 001.32-1.32V3.144c0-.528-.44-.968-1.012-.968zM7.826 36.98V3.54h24.64l-.046 33.44H7.826z"
          fill="#A6C0FF"
          fillRule="nonzero"
        />
        <g fill="#5D8BFF">
          <path fillRule="nonzero" d="M20.124 15.86h-9.812v1.98h9.812z" />
          <path d="M10.312 19.776v1.98h19.58v-1.98h.044zM23.952 27.652h-13.64v1.98h13.64zM26.152 23.692h-15.84v1.98h15.84zM12.292 6.664h-1.98v6.6h1.98zM26.02 6.664h-1.98v6.6h1.98zM22.06 6.664h-1.98v6.6h1.98zM29.936 6.664h-1.98v6.6h1.98zM18.144 6.664h-3.916v6.6h3.916z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
