import React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"navIcon--products"}</title>
      <path
        d="M34.851 4h.006C35.488 4 36 4.512 36 5.143v.006l-.067 13.781a1.136 1.136 0 01-.335.802L19.665 35.665a1.14 1.14 0 01-1.616 0L4.335 21.951a1.14 1.14 0 010-1.616L20.267 4.402c.206-.206.49-.334.803-.335L34.851 4zm-1.142 2.291l-12.158.059L6.76 21.143l12.098 12.098 14.793-14.793.059-12.157h-.001zm-6.852 4.566a2.304 2.304 0 012.286 2.286 2.304 2.304 0 01-2.286 2.286 2.304 2.304 0 01-2.286-2.286 2.304 2.304 0 012.286-2.286z"
        fill="#5D8BFF"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgComponent;
