import { useSelector } from "react-redux";
import { getAppUserCapabilities } from "utils/mapStateToProps";
import { useRouter } from "next/router";
import { authedRoutes, unauthedRoutes } from "components/Nav/routes";
import { useViewport } from "utils/context";
import { useAppHubRoute } from "./hub";
import { useIsAuthed } from "utils/selectors";
import { USER_CAPABILITY } from "utils/constants/capability";
import { useUserFeature } from "./capabilities";

export const useRouteConfig = () => {
  const isAuthed = useIsAuthed();
  const userFeatures = {
    beta: useUserFeature(USER_CAPABILITY.BETA),
    campaigns: useUserFeature(USER_CAPABILITY.CAMPAIGNS),
    shipping_rates: useUserFeature(USER_CAPABILITY.SHIPPING_RATES),
    insights: useUserFeature(USER_CAPABILITY.INSIGHTS),
    pages: useUserFeature(USER_CAPABILITY.PAGES)
  };

  const capabilities = useSelector((state) => getAppUserCapabilities(state));
  const { route } = useRouter();
  const { isDesktop } = useViewport();
  const appRoot = useAppHubRoute();
  const input = { capabilities, route, isDesktop, appRoot, userFeatures };

  return isAuthed ? authedRoutes(input) : unauthedRoutes(input);
};
