import React from "react";

const Files = (props) => {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"navIcon--files"}</title>
      <g
        id="navIcon--files"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group"
          transform="translate(1.000000, 4.000000)"
          fillRule="nonzero"
        >
          <path
            d="M25.0037823,21.6767618 L19.9622894,16.7870741 C19.5694939,16.4043091 18.9302749,16.4043091 18.5374794,16.7870741 L13.4959865,21.6767618 C13.1013382,22.0595267 13.1013382,22.6777011 13.4959865,23.0586691 C13.6923842,23.249153 13.9499247,23.344395 14.2093179,23.344395 C14.4668583,23.344395 14.7262516,23.249153 14.9226493,23.0586691 L18.2428828,19.8384117 L18.2428828,30.9224226 C18.2428828,31.4615281 18.6949682,31.9000007 19.2508108,31.9000007 C19.8066535,31.9000007 20.2587388,31.4615281 20.2587388,30.9224226 L20.2587388,19.8384117 L23.5789723,23.0586691 C23.77537,23.249153 24.0329105,23.344395 24.2923037,23.344395 C24.5498442,23.344395 24.8092374,23.249153 25.0056351,23.0586691 C25.3984306,22.6777011 25.3984306,22.0577297 25.0037823,21.6767618 Z"
            id="Path"
            fill="#A6C0FF"
          ></path>
          <path
            d="M29.7776978,10.2392624 C29.2909702,4.5112655 24.5072924,0 18.7000004,0 C12.5694618,0 7.58143271,5.02603646 7.58143271,11.203288 C7.58143271,11.7143151 7.61858749,12.2272142 7.69103931,12.7401133 C7.57028628,12.7344976 7.44953324,12.7307538 7.32878021,12.7307538 C3.28819797,12.7307538 0,16.0440069 0,20.1153772 C0,24.1867475 3.28819797,27.5 7.32878021,27.5 L11.4469504,27.5 C12.0061298,27.5 12.4575604,27.0432584 12.4575604,26.48169 C12.4575604,25.9201217 12.0042721,25.4633795 11.4469504,25.4633795 L7.32878021,25.4633795 C4.40284134,25.4633795 2.02121999,23.0654828 2.02121999,20.1153772 C2.02121999,17.1652716 4.4009836,14.7673749 7.32878021,14.7673749 C7.78392626,14.7673749 8.25021874,14.8328912 8.71651122,14.96018 C9.06762388,15.0556466 9.44288715,14.9545643 9.69925513,14.6924991 C9.9556231,14.4323058 10.0522255,14.0523112 9.95376537,13.6985232 C9.71969026,12.8674021 9.6026527,12.0269215 9.6026527,11.2014161 C9.6026527,6.1473012 13.6841052,2.03474921 18.7000004,2.03474921 C23.7158956,2.03474921 27.7973481,6.1473012 27.7973481,11.2014161 C27.7973481,11.7648563 28.2506364,12.2197266 28.8079581,12.2197266 C32.4305491,12.2197266 35.3787808,15.190423 35.3787808,18.8406171 C35.3787808,22.4908112 32.4324068,25.4615076 28.8079581,25.4615076 L27.0530505,25.4615076 C26.493871,25.4615076 26.0424405,25.9182498 26.0424405,26.4798181 C26.0424405,27.0432584 26.4957288,27.4981287 27.0530505,27.4981287 L28.8079581,27.4981287 C33.5451925,27.4981287 37.4000008,23.6139478 37.4000008,18.8406171 C37.4000008,14.3986117 34.0616439,10.7259549 29.7776978,10.2392624 Z"
            id="Path"
            fill="#5D8BFF"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default Files;
