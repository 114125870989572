import React from "react";
import PropTypes from "prop-types";
export const PADDER_SMALL = "small";
export const PADDER_VISIBLE = "visible";
const THEME_CLASSES = {
  [PADDER_VISIBLE]: "dib h5-5 w-100",
  [PADDER_SMALL]: "dib dn-ns h5-5 w-100"
};

const Padder = ({ theme, customClasses }) => (
  <div className={THEME_CLASSES[theme] || customClasses} />
);

Padder.propTypes = {
  customClasses: PropTypes.string
};

Padder.defaultProps = {
  customClasses: THEME_CLASSES.small
};

export default Padder;
