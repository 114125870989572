import C from "constants/organizationWorkflow";
import ActionHelpers from "./helpers";

export function setLoading() {
  return {
    type: C.UPDATE_ORGANIZATION_WORKFLOW_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setOrganizationWorkflows = (payload) => {
  return {
    type: C.SET_ORGANIZATION_WORKFLOWS,
    payload
  };
};

export const replaceOrganizationWorkflows = (payload) => {
  return {
    type: C.REPLACE_ORGANIZATION_WORKFLOWS,
    payload
  };
};

/**
 * resetOrganizationWorkflowNotes
 */
export function resetOrganizationWorkflowNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_ORGANIZATION_WORKFLOW_STORE,
      payload
    });
  };
}

export function showOrganizationWorkflowNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_ORGANIZATION_WORKFLOW_STORE,
        message || `Success!`
      )
    );
  };
}
