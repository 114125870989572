import C from "constants/webhook";
import { reduceModelActions } from "utils/action";
import { prepareModelAssociations } from "utils/data";
import ActionHelpers, { withMethod } from "./helpers";
import { ACTION_TYPE } from "utils/constants/action";
import { apiResourcePath } from "utils/paths";
import { setOrganizationWebhooks } from "./organizationWebhook";
import { setUserWebhooks } from "./userWebhook";
import { METHODS } from "utils/constants/auth";

export function setLoading() {
  return {
    type: C.UPDATE_WEBHOOK_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setWebhooks = (payload) => {
  return {
    type: C.SET_WEBHOOKS,
    payload
  };
};

export const removeWebhooks = (payload) => {
  return {
    type: C.REMOVE_WEBHOOKS,
    payload
  };
};

export const replaceWebhooks = (payload) => {
  return {
    type: C.REPLACE_WEBHOOKS,
    payload
  };
};

/**
 * resetWebhookNotes
 */
export function resetWebhookNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_WEBHOOK_STORE,
      payload
    });
  };
}

export function showWebhookNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(C.UPDATE_WEBHOOK_STORE, message || `Success!`)
    );
  };
}

export const WEBHOOK_MODELS = ["webhooks"];

export const WEBHOOK_ACTIONS_MAP = {
  setWebhooks,
  setOrganizationWebhooks,
  setUserWebhooks
};

/**
 * fetchWebhooks
 */
export function fetchWebhooks(
  { resourceUUID, resource, resourceID },
  callback = () => {}
) {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/webhooks`;

    return withMethod({
      method: METHODS.GET,
      url: REQUEST_URL,
      onComplete: (responseData) => {
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          webhooks: responseData
        });

        return reduceModelActions({
          keys: WEBHOOK_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: WEBHOOK_ACTIONS_MAP
        });
      },
      constant: C.UPDATE_WEBHOOK_STORE,
      dispatch,
      callback
    });
  };
}

export const updateWebhook = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/webhooks/${uuid}`;

    return withMethod({
      method: METHODS.PUT,
      url: REQUEST_URL,
      data,
      onComplete: (webhook) => {
        const webhooks = Array.isArray(webhook) ? webhook : [webhook];

        return [
          ActionHelpers.clearLoader(C.UPDATE_METADATA_STORE),
          setWebhooks(webhooks),
          ActionHelpers.successNote(C.UPDATE_METADATA_STORE, "Updated")
        ];
      },
      constant: C.UPDATE_WEBHOOK_STORE,
      dispatch,
      callback
    });
  };
};

export const deleteWebhook = (
  { resourceUUID, resource, uuid },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/webhooks/${uuid}`;

    return withMethod({
      method: METHODS.DEL,
      url: REQUEST_URL,
      onComplete: (webhook) => {
        return [
          removeWebhooks([webhook]),
          ActionHelpers.successNote(C.UPDATE_WEBHOOK_STORE, "Webhook deleted")
        ];
      },
      constant: C.UPDATE_WEBHOOK_STORE,
      dispatch,
      callback
    });
  };
};
