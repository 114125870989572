import C from "constants/organizationAttachment";
import ActionHelpers from "./helpers";

export function setLoading() {
  return {
    type: C.UPDATE_ORGANIZATION_ATTACHMENT_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setOrganizationAttachments = (payload) => {
  return {
    type: C.SET_ORGANIZATION_ATTACHMENTS,
    payload
  };
};

export const replaceOrganizationAttachments = (payload) => {
  return {
    type: C.REPLACE_ORGANIZATION_ATTACHMENTS,
    payload
  };
};

/**
 * resetOrganizationAttachmentNotes
 */
export function resetOrganizationAttachmentNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_ORGANIZATION_ATTACHMENT_STORE,
      payload
    });
  };
}

export function showOrganizationAttachmentNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_ORGANIZATION_ATTACHMENT_STORE,
        message || `Success!`
      )
    );
  };
}
