import React, { Fragment, useEffect } from "react";
import { useRouter } from "next/router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Head from "components/Head";
import Nav from "components/Nav";
import AppNote from "components/AppNote";
import PATHS, {
  UNAUTHED_ROUTES,
  AUTHED_REDIRECT_ROUTES,
  MIXED_AUTH_ROUTES
} from "utils/paths";
import { getJWT } from "utils/auth";
import { COLORS } from "utils/styles";
import { fetchAppConfiguration } from "actions/app";
import { fetchCapabilities } from "actions/capability";
import { fetchUserDetails, logout } from "actions/auth";
import isError from "lodash/isError";
import isEmpty from "lodash/isEmpty";
import Analytics, { USER_LOGIN, IS_PROD } from "utils/analytics";
import { getPageMetaProps } from "utils/constants/meta";
import dynamic from "next/dynamic";
import { hideNavBar, isGreyBGPath } from "utils/route";
import ErrorBoundary from "components/ErrorBoundary";
import { ensureAppConfiguration, ensureCapabilities } from "utils/hooks";
import NavBarTopBlock from "components/NavBarTopBlock";
import {
  useAnyIncompleteOnboardings,
  useAppOnboardingConfig,
  useAuthLoading,
  useCtxReferences,
  useUser
} from "utils/selectors";
import { getUserTrackingData } from "utils/user";
import {
  BILLING_PLAN_CONFIG,
  ENABLED_ENTITLEMENTS_CONFIG
} from "utils/constants/billingPlan";
import { PriceBlocs } from "@priceblocs/react-priceblocs-js";
import { useEmbedEnabled } from "utils/hooks/embed";
/**
 * Uncomment for app wide notice
 * import AppNoticeBar from "components/AppNoticeBar";
 * import { CLOUD_PROVIDER_ISSUE } from "utils/constants/notice";
 */

const NoComponent = () => <span />;
const CookieConsent = dynamic(() => import("components/CookieConsent"), {
  ssr: false,
  loading: NoComponent
});

const DEFAULT_CLASSSES = {
  container: "layout relative h-100"
};

const Layout = (props) => {
  const { children, customClasses, metadata, loading, actions } = props;
  const classes = { ...DEFAULT_CLASSSES, ...customClasses };
  const { customerId } = useCtxReferences();

  const router = useRouter();
  const user = useUser();
  const authLoading = useAuthLoading();
  const hasAppOnboardingConfig = !isEmpty(useAppOnboardingConfig());
  const hasIncompleteOnboardings = useAnyIncompleteOnboardings();
  ensureCapabilities({
    fetchCapabilities: actions.fetchCapabilities
  });
  ensureAppConfiguration({
    fetchAppConfiguration: actions.fetchAppConfiguration
  });
  const isEmbed = useEmbedEnabled();

  const userID = user.id;
  const jwtVal = getJWT();
  const canFetchAuth = jwtVal && !userID;
  const authedUser = userID && jwtVal;
  const pathname = router.pathname;
  const showNav = !hideNavBar(pathname);

  const isGreyBg = isGreyBGPath({
    pathname: pathname,
    query: router.query
  });

  const userData = getUserTrackingData(user);

  const logoutAndRedirect = () => {
    router.replace(PATHS.LOGIN);
    actions.logout();
  };
  /**
   * Entry point from external APIs (e.g Stripe)
   * Where we want to do some local component handshake logic without being redirected through onboarding even if eligible
   */
  const authedRedirectRoute = AUTHED_REDIRECT_ROUTES.indexOf(pathname) >= 0;
  const mixedAuthRoute = MIXED_AUTH_ROUTES.indexOf(pathname) >= 0;
  const unauthedRoute = UNAUTHED_ROUTES.indexOf(pathname) >= 0;
  const isOnboardingRoute = pathname === PATHS.ONBOARDING;

  const needsRefetchAuth = canFetchAuth && !authLoading;
  const needsRedirectAuthRefresh = authedRedirectRoute && !authLoading;
  const needsMixedAuthRefresh = mixedAuthRoute && !authLoading;
  const needsOnboardingCheck = !unauthedRoute && !authLoading;

  const refetchRedirectAuth = needsRedirectAuthRefresh && canFetchAuth;
  const refetchMixedAuth = needsMixedAuthRefresh && canFetchAuth;

  const refetchAuthWithLogoutOnFail = refetchRedirectAuth || needsRefetchAuth;
  const softRefetchAuth = refetchMixedAuth;

  const goToOnboarding =
    hasIncompleteOnboardings &&
    hasAppOnboardingConfig &&
    !isOnboardingRoute &&
    !authedRedirectRoute &&
    needsOnboardingCheck;

  /**
   * A. Need to fetch the users details
   * - expect the JWT to be valid so can refresh user
   * - logout user and clear JWT if request fails
   * B. Need to complete onboardings
   * C. Mixed auth refresh user
   * - JWT may be valid - refresh user
   * - but dont logout and redirect if request fails
   */
  useEffect(() => {
    if (refetchAuthWithLogoutOnFail) {
      // A.
      actions.fetchUserDetails(true, (err) => {
        if (isError(err)) {
          logoutAndRedirect();
        }
      });
    } else if (goToOnboarding) {
      // B.
      router.push(PATHS.ONBOARDING);
    } else if (softRefetchAuth) {
      // C.
      actions.fetchUserDetails(true);
    }
  }, [refetchAuthWithLogoutOnFail, softRefetchAuth, goToOnboarding]);

  /**
   * Set indentifiers when the user has just transitioned into an authed state
   * i.e. there are user level identifiers in the reducer
   * Dont want this firing too much in development so scope to production
   */
  useEffect(() => {
    if (userID && IS_PROD) {
      Analytics.setIdentifiers(userData, USER_LOGIN);
    }
  }, [userID, IS_PROD]);
  /**
   * Check if the user needs to have a session registered
   * - User may have left tab open and come back so we check date and fire a session date event for them
   */
  useEffect(() => {
    if (authedUser) {
      Analytics.registerUserSession(userData);
    }
  }, [authedUser]);

  if (!children) {
    throw new Error("Layout requires child components");
  }

  const billingConfig = { ...BILLING_PLAN_CONFIG };
  /**
   * Update billing config with customer id and additional config
   */
  if (customerId) {
    billingConfig.customer = customerId;
    billingConfig.query = {
      customer: {
        associations: ["invoices"]
      }
    };
    billingConfig.entitlements = ENABLED_ENTITLEMENTS_CONFIG;
    billingConfig.features = {
      enabled: true
    };
  }

  return (
    <ErrorBoundary>
      <PriceBlocs {...billingConfig}>
        {() => (
          <div className={classes.container}>
            <Head {...getPageMetaProps({ path: pathname, metadata })} />
            {showNav && (
              <Fragment>
                <Nav loading={loading} />
                <NavBarTopBlock />
              </Fragment>
            )}
            {children && children()}
            <AppNote />
            {/* <AppNoticeBar content={CLOUD_PROVIDER_ISSUE} /> */}
            {!isEmbed && <CookieConsent />}
            <style jsx global>
              {`
          :global(html),
          :global(body) {
            background-color: ${isGreyBg ? COLORS.bg : COLORS.white};
        `}
            </style>
          </div>
        )}
      </PriceBlocs>
    </ErrorBoundary>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchUserDetails,
      fetchAppConfiguration,
      fetchCapabilities,
      logout
    },
    dispatch
  )
});

export default connect(null, mapDispatchToProps)(Layout);
