import React from "react";

const Zapier = (props) => {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"navIcon--zapier"}</title>
      <path
        d="M24.5 20.008c0 1.337-.245 2.618-.691 3.8a10.762 10.762 0 01-3.801.692h-.016c-1.337 0-2.618-.245-3.8-.691a10.766 10.766 0 01-.692-3.8v-.016c0-1.338.246-2.618.691-3.8a10.772 10.772 0 013.801-.693h.016c1.338.001 2.619.247 3.8.693.446 1.182.691 2.462.692 3.8v.015zM37.75 17H27.243l7.43-7.43a18.095 18.095 0 00-4.243-4.243L23 12.757V2.25A18.11 18.11 0 0020.01 2h-.02a18.11 18.11 0 00-2.99.25v10.507l-7.43-7.43a18.085 18.085 0 00-2.298 1.945l-.002.002A18.09 18.09 0 005.328 9.57l7.43 7.43H2.25S2 18.974 2 19.993v.013c0 1.004.084 2.005.25 2.994h10.507l-7.43 7.43a18.102 18.102 0 004.243 4.242L17 27.243V37.75c.987.166 1.986.25 2.987.25h.026c1.001 0 2-.084 2.987-.25V27.243l7.43 7.43a18.093 18.093 0 002.297-1.944l.002-.002c.71-.71 1.36-1.479 1.943-2.297L27.242 23H37.75c.166-.987.25-1.986.25-2.987v-.026c0-1-.084-2-.25-2.987z"
        fill="#FF4A00"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default Zapier;
