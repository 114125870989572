import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getCtxResourceCapabilityAndIDs } from "utils/mapStateToProps";
import { useToasts } from "react-toast-notifications";
import {
  checkout,
  buildPlatformSubscriptionCheckoutParams,
  billing,
  buildBillingParams
} from "utils/checkout/actions";
import Analytics, {
  PREPARE_PRICING_SESSION,
  PREPARE_BILLING_SESSION
} from "utils/analytics";

const getErrorMessage = (fragment) =>
  `There was an error creating an authenticated ${fragment} session.`;

export const AuthedPricingHOC = (WrappedComponent, props) => {
  // authedRoutes
  const [loading, setLoading] = useState(false);
  const { customerId, resourceUUID, resource } = useSelector((state) =>
    getCtxResourceCapabilityAndIDs(state)
  );
  const { addToast } = useToasts();
  const setError = () => {
    addToast(getErrorMessage("pricing"), {
      appearance: "error",
      autoDismiss: false
    });
  };

  // https://stripe.com/docs/api/checkout/sessions/object
  const input = {
    customer: customerId,
    resource,
    resourceUUID
  };
  const session = buildPlatformSubscriptionCheckoutParams(input);

  return (
    <WrappedComponent
      {...props}
      loading={loading}
      onClick={() => {
        Analytics.track(PREPARE_PRICING_SESSION, input);
        checkout({ session, setLoading, setError })();
      }}
    />
  );
};

export const AuthedBillingHOC = (WrappedComponent, props) => {
  // authedRoutes
  const [loading, setLoading] = useState(false);
  const { customerId, resourceUUID, resource } = useSelector((state) =>
    getCtxResourceCapabilityAndIDs(state)
  );
  const { addToast } = useToasts();
  const setError = () => {
    addToast(getErrorMessage("billing"), {
      appearance: "error",
      autoDismiss: false
    });
  };

  // https://stripe.com/docs/api/customer_portal/create
  const session = buildBillingParams({ customer: customerId });

  return (
    <WrappedComponent
      {...props}
      loading={loading}
      onClick={() => {
        Analytics.track(PREPARE_BILLING_SESSION, {
          customer: customerId,
          resource,
          resourceUUID
        });
        billing({ session, setLoading, setError })();
      }}
    />
  );
};
