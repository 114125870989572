import React from "react";

const Tutorial = (props) => {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"navIcon--blog"}</title>
      <g fillRule="nonzero" fill="none">
        <path
          d="M30.968 4c.328.085.651.19.966.317a3.56 3.56 0 012.237 3.428c.025 3.646 0 7.286 0 10.939 0 .07 0 .142-.023.234a3.855 3.855 0 00-2.42.288V8.926H7.262c0 .102-.02.212-.02.317v20.23c0 .986.466 1.452 1.442 1.452h12.038c-.234.798-.456 1.584-.693 2.335a.272.272 0 01-.213.114H8.643a3.684 3.684 0 01-3.672-2.582c-.063-.206-.114-.415-.171-.634V7.222c.024-.052.044-.106.06-.162a3.574 3.574 0 012.113-2.724A8.215 8.215 0 018.031 4h22.937zM29.574 24.373l3.877 3.846a5.95 5.95 0 01-.2.213c-1.9 1.9-3.81 3.801-5.727 5.702a1.312 1.312 0 01-.52.317c-1.352.412-2.711.808-4.074 1.197a.97.97 0 01-.481.026.507.507 0 01-.342-.634c.422-1.478.852-2.945 1.29-4.4a.71.71 0 01.183-.282c1.977-1.992 3.954-3.973 5.93-5.943a.193.193 0 01.064-.042zM26.63 33.41c-.114-.606-.222-1.185-.34-1.759a.253.253 0 00-.142-.17c-.58-.13-1.166-.248-1.761-.368-.216.735-.403 1.444-.634 2.138a.634.634 0 00.212.694c.15.165.394.2.583.085l2.082-.62z"
          fill="#5D8BFF"
        />
        <path
          fill="#A6C0FF"
          d="M28.037 18.706v2.401H10.93v-2.401zM28.034 13.82v2.405H10.927v-2.404zM26.89 23.607c-1.13.795-2.217 1.584-3.339 2.338a.827.827 0 01-.405.088H11.253c-.101 0-.203 0-.332-.025v-2.401h15.97z"
        />
        <path
          d="M34.627 26.81l-3.662-3.615c.541-.577 1.048-1.179 1.625-1.698a1.175 1.175 0 011.543.08c.69.652 1.365 1.323 2.014 2.017a1.267 1.267 0 010 1.698c-.066.08-.137.156-.212.228l-1.308 1.29z"
          fill="#5D8BFF"
        />
      </g>
    </svg>
  );
};

export default Tutorial;
