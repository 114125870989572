import C from "constants/organizationContactlessConfig";
import ActionHelpers from "./helpers";

export function setLoading() {
  return {
    type: C.UPDATE_ORGANIZATION_CONTACTLESS_CONFIG_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setOrganizationContactlessConfigs = (payload) => {
  return {
    type: C.SET_ORGANIZATION_CONTACTLESS_CONFIGS,
    payload
  };
};

export const replaceOrganizationContactlessConfigs = (payload) => {
  return {
    type: C.REPLACE_ORGANIZATION_CONTACTLESS_CONFIGS,
    payload
  };
};

/**
 * resetOrganizationContactlessConfigNotes
 */
export function resetOrganizationContactlessConfigNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_ORGANIZATION_CONTACTLESS_CONFIG_STORE,
      payload
    });
  };
}

export function showOrganizationContactlessConfigNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_ORGANIZATION_CONTACTLESS_CONFIG_STORE,
        message || `Success!`
      )
    );
  };
}
