import React from "react";
import PropTypes from "prop-types";
import SubNav from "./SubNav";
import { COLORS } from "utils/styles";
import Pill from "components/Pill";
import classnames from "classnames";
import Analytics, { CLICK_NAV_LINK } from "utils/analytics";
import { useNavContext } from "utils/context/nav";
import ZoneLink from "components/ZoneLink";

const ICON_DIMENSION = 52;
const ICON_STYLES = {
  height: ICON_DIMENSION,
  width: ICON_DIMENSION
};

/**
 * The top level navigation node e.g. Payment Links, Learn, Documentation
 */
const LinkNode = ({
  href,
  hoc,
  routeProps,
  linkProps,
  IconComp,
  label,
  zone,
  isActive,
  highlight,
  subnav,
  sectionId,
  callout,
  closeMenu,
  showAlways
}) => {
  const { values, setValues } = useNavContext();
  const activeSection = values.activeSection;
  /**
   * Show
   * - Mobile - always want to show the subnav - as opposed to only on hover
   * - Debug - for desktop debuging / dev set this to true
   */
  // const show = true;
  const show = showAlways || activeSection === sectionId;
  const calloutComp = callout && (
    <Pill
      copy={callout}
      customClasses={{
        container: "pl2",
        copy: "f7 bg-black-05 pa1 br2 fw5 flex-shrink-0 ph2 fw6"
      }}
    />
  );
  let content = IconComp ? <IconComp {...ICON_STYLES} /> : label;
  const mainContent = (
    <div className="flex flex-row items-center">
      {content}
      {calloutComp}
    </div>
  );
  const trackNavClick = () => {
    Analytics.track(CLICK_NAV_LINK, {
      href
    });
  };

  /**
   * If href
   * - if zone tag is a tag else Link tag
   */

  let linkContent;
  if (href) {
    linkProps.onClick = () => {
      trackNavClick();
      if (show) {
        setValues({
          close: true,
          activeSection: null
        });
      }
    };
    const ZoneLinkProps = {
      routeProps,
      linkProps,
      href,
      zone
    };
    linkContent = <ZoneLink {...ZoneLinkProps}>{mainContent}</ZoneLink>;
  } else if (hoc) {
    linkContent = hoc(({ onClick }) => {
      return (
        <div className="pointer link" onClick={onClick} {...linkProps}>
          {mainContent}
        </div>
      );
    });
  } else {
    linkContent = <div {...linkProps}>{mainContent}</div>;
  }

  return (
    <div
      className={classnames("relative z-2 h-100-l", {
        "z-6": show,
        "z-2": !show
      })}
    >
      <li
        onMouseEnter={() => {
          setValues({
            ...values,
            activeSection: sectionId
          });
        }}
        className="navLink pointer ph0 ph2-l dib h-100 flex items-center bb w-100 relative user-select-n bg-white--hover"
      >
        {linkContent}
        <style jsx>{`
          .navLink {
            border-width: 0.2rem;
            outline: none;
            color: ${COLORS.primary};
            border-color: ${isActive && highlight
              ? COLORS.water_blue
              : COLORS.white};
          }
          .navLink__label {
            color: ${COLORS.primary};
          }
          .navLink__label:hover {
            background-color: ${COLORS.whitesmoke};
          }
        `}</style>
      </li>
      {subnav && show && (
        <SubNav key={href} show={show} closeMenu={closeMenu} {...subnav} />
      )}
    </div>
  );
};

LinkNode.propTypes = {
  href: PropTypes.string,
  hoc: PropTypes.func,
  routeProps: PropTypes.object.isRequired,
  linkProps: PropTypes.object.isRequired,
  IconComp: PropTypes.func,
  closeMenu: PropTypes.func,
  label: PropTypes.string,
  isActive: PropTypes.bool,
  highlight: PropTypes.bool.isRequired,
  showAlways: PropTypes.bool,
  callout: PropTypes.string,
  sectionId: PropTypes.string
};

export default LinkNode;
