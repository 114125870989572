import React from "react";
import Image from "components/Image";

const Brand = (props) => {
  return (
    <Image
      src={`/assets/brand.png`}
      width="40"
      height="40"
      alt={"PriceBlocs Logo"}
    />
  );
};

export default Brand;
