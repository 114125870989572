import C from "constants/workflow";
import { reduceModelActions } from "utils/action";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { prepareModelAssociations } from "utils/data";
import ActionHelpers from "./helpers";
import { ACTION_TYPE } from "utils/constants/action";
import { apiResourcePath } from "utils/paths";
import { setOrganizationWorkflows } from "./organizationWorkflow";
import { setUserWorkflows } from "./userWorkflow";
import { updateManifestAction } from "./manifest";

export function setLoading() {
  return {
    type: C.UPDATE_WORKFLOW_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setWorkflows = (payload) => {
  return {
    type: C.SET_WORKFLOWS,
    payload
  };
};

export const removeWorkflows = (payload) => {
  return {
    type: C.REMOVE_WORKFLOWS,
    payload
  };
};

export const replaceWorkflows = (payload) => {
  return {
    type: C.REPLACE_WORKFLOWS,
    payload
  };
};

/**
 * resetWorkflowNotes
 */
export function resetWorkflowNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_WORKFLOW_STORE,
      payload
    });
  };
}

export function showWorkflowNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(C.UPDATE_WORKFLOW_STORE, message || `Success!`)
    );
  };
}

export const WORKFLOW_MODELS = ["workflows"];

export const WORKFLOW_ACTIONS_MAP = {
  setWorkflows,
  setOrganizationWorkflows,
  setUserWorkflows
};

/**
 * fetchWorkflows
 */
export function fetchWorkflows(
  { resourceUUID, resource, resourceID, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_WORKFLOW_STORE,
        "update",
        "workflow",
        "Fetch workflows"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/workflows`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      params: data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          workflows: responseData
        });

        const actions = reduceModelActions({
          keys: WORKFLOW_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: WORKFLOW_ACTIONS_MAP
        });

        dispatch(batchActions(actions));
        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_WORKFLOW_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

export const updateWorkflow = (
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/workflows/${uuid}`;

    return axios({
      method: "put",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: workflow }) => {
        const workflows = Array.isArray(workflow) ? workflow : [workflow];

        const actions = [
          ActionHelpers.clearLoader(C.UPDATE_METADATA_STORE),
          setWorkflows(workflows),
          ActionHelpers.successNote(C.UPDATE_METADATA_STORE, "Updated")
        ];

        dispatch(batchActions(actions));
        callback(null, workflow);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_METADATA_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

export const deleteWorkflow = (
  { resourceUUID, resource, uuid },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/workflows/${uuid}`;

    return axios({
      method: "delete",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        dispatch(
          batchActions([
            removeWorkflows([responseData]),
            ActionHelpers.successNote(
              C.UPDATE_WORKFLOW_STORE,
              "Workflow deleted"
            )
          ])
        );

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_WORKFLOW_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

export const createManifestWorkflow = (
  { resourceID, resourceUUID, resource, uuid, data },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/manifests/${uuid}/workflows`;

    return axios({
      method: "post",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: workflow }) => {
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          workflows: [workflow]
        });

        const actions = reduceModelActions({
          keys: WORKFLOW_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: WORKFLOW_ACTIONS_MAP
        });

        actions.push(
          updateManifestAction({
            uuid,
            workflows: [workflow.id]
          })
        );

        dispatch(batchActions(actions));
        callback(null, workflow);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_METADATA_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};
