import React, { useState, useEffect } from "react";
import { useIsMounted } from "utils/hooks";
import { createUseContext } from ".";

export const {
  Context: NavContext,
  ContextProvider: NavContextProvider,
  useContext: useNavContext
  /* eslint-disable-next-line react/display-name, react/prop-types */
} = createUseContext((Provider) => ({ children }) => {
  const isMounted = useIsMounted();
  const [values, setValues] = useState({
    open: false,
    activeSection: null
  });

  const handleMouseLeave = () => {
    if (isMounted.current) {
      setValues({
        open: false,
        activeSection: null
      });
    }
  };

  /**
   * Close the nav when user moves the cursor off screen
   */
  useEffect(() => {
    if (typeof document !== "undefined") {
      document.body.addEventListener("mouseleave", handleMouseLeave);
    }
    return () => {
      if (typeof document !== "undefined") {
        document.body.addEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, []);

  return (
    <Provider
      value={{
        values,
        setValues
      }}
    >
      {children}
    </Provider>
  );
});
