import React from "react";
import Button from "components/Form/fields/Button";
import { logout } from "actions/auth";
import { BUTTON_TAGS, UI_THEME } from "utils/constants/ui";
import PATHS from "utils/paths";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useRouter } from "next/router";
import { useNavContext } from "utils/context/nav";
import { useIsAuthed } from "utils/selectors";
import { isZone } from "utils/nav";
import { getMainAppPath } from "utils/route";
import { useViewport } from "utils/context";

const DEFAULT_CLASSES = {
  contextClass: " ",
  button:
    "bn bg-transparent f4 f5-l pa3 pa2-l pointer br2 w-100 outline-0 link color-text--primary fw5 bg-white--hover dib"
};

/**
 * In main
 * - login - relative /login path
 * - logout - replace route
 * In a zone
 * - login - absolute /login path
 * - logout - no route replacement
 */
const AuthButton = ({ actions, customClasses }) => {
  const { isSmall } = useViewport();
  const { values, setValues } = useNavContext();
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const isAuthed = useIsAuthed();
  const { replace } = useRouter();
  const currentlyInZone = isZone();

  const loginLogoutProps = {
    theme: "slim",
    copy: isAuthed ? "Logout" : "Login"
  };

  let signupButton;
  if (isAuthed) {
    loginLogoutProps.tag = BUTTON_TAGS.BUTTON;
    loginLogoutProps.onClick = () => {
      actions.logout();
      setValues({
        ...values,
        open: false
      });
      /**
       * Only redirect to login if not in a zone
       */
      if (!currentlyInZone) {
        replace(PATHS.LOGIN);
      }
    };
  } else {
    const unauthedButtonTag = currentlyInZone
      ? BUTTON_TAGS.ANCHOR
      : BUTTON_TAGS.LINK;
    loginLogoutProps.tag = unauthedButtonTag;
    loginLogoutProps.href = getMainAppPath(PATHS.LOGIN);

    const signupProps = {
      copy: "Sign up",
      tag: unauthedButtonTag,
      href: getMainAppPath(PATHS.SIGNUP)
    };
    if (!isSmall) {
      signupProps.theme = UI_THEME.SLIM;
    }

    signupButton = (
      <div className="pa3 pv0-ns pr2-ns">
        <Button
          id="signup-button"
          {...signupProps}
          classes={{
            button:
              "button--primary fw6 br2 ph3 pv2 border-box input-reset ba b--black bg-transparent pointer f4 f5-l dib link items-center flex w-100 ta-man nowrap"
          }}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-row-ns flex-column">
      {signupButton}
      <Button
        id="login-logout-button"
        {...loginLogoutProps}
        classes={classes}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      logout
    },
    dispatch
  )
});

export default connect(null, mapDispatchToProps)(AuthButton);
