import React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"navIcon--taxRates"}</title>
      <g
        transform="translate(8.5 8)"
        stroke="#5D8BFF"
        strokeWidth={2.4}
        fill="none"
        fillRule="evenodd"
      >
        <path d="M.36 22.8L23.16 0" />
        <circle cx={4.8} cy={5.64} r={3.6} />
        <circle cx={17.64} cy={18.36} r={3.6} />
      </g>
    </svg>
  );
}

export default SvgComponent;
