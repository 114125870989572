import React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"navIcon--pages"}</title>
      <path
        d="M8.265 36.96C7.011 36.96 6 35.962 6 34.725V4.235C6 2.998 7.011 2 8.265 2h24.47C33.989 2 35 2.998 35 4.235v21.87c0 .24-.08.479-.283.678l-10.678 9.978c-.162.12-.364.239-.607.239H8.265v-.04zm16.098-2.993l7.482-6.984h-7.482v6.984zm8.817-8.74V4.235c0-.28-.162-.44-.445-.44H8.265c-.283 0-.445.16-.445.44v30.49c0 .28.162.44.445.44h14.278v-9.06c0-.479.404-.878.89-.878h9.747z"
        fill="#5D8BFF"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgComponent;
