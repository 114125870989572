import C from "constants/userAudience";

export function setLoading() {
  return {
    type: C.UPDATE_USER_AUDIENCE_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setUserAudiences = (payload) => {
  return {
    type: C.SET_USER_AUDIENCES,
    payload
  };
};

export const replaceUserAudiences = (payload) => {
  return {
    type: C.REPLACE_USER_AUDIENCES,
    payload
  };
};
