import React from "react";

function SvgComponent(props) {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
      <title>{"navIcon--plan"}</title>
      <g fill="#5D8BFF" fillRule="nonzero">
        <path
          d="M3.137 34.6h6.825c.629 0 1.138-.56 1.138-1.25v-12.5c0-.69-.51-1.25-1.138-1.25H3.137C2.51 19.6 2 20.16 2 20.85v12.5c0 .69.51 1.25 1.137 1.25zm1.138-12.733h4.55v11h-4.55v-11zM29.137 13.8c-.628 0-1.137.582-1.137 1.3v18.2c0 .718.51 1.3 1.137 1.3h6.825c.629 0 1.138-.582 1.138-1.3V15.1c0-.718-.51-1.3-1.138-1.3h-6.825zM34.825 32h-4.55V16.4h4.55V32z"
          fillOpacity={0.551}
        />
        <path d="M22.962 6h-6.825C15.51 6 15 6.64 15 7.43v25.74c0 .79.51 1.43 1.137 1.43h6.825c.629 0 1.138-.64 1.138-1.43V7.43c0-.79-.51-1.43-1.138-1.43zm-1.137 25.22h-4.55V9.12h4.55v22.1z" />
      </g>
    </svg>
  );
}

export default SvgComponent;
