import React from "react";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import { NavContextProvider } from "utils/context/nav";

const Nav = ({ loading }) => {
  return (
    <NavContextProvider>
      <div className="w-100" id="nav">
        <DesktopNav key="desktopNav" loading={loading} />
        <MobileNav key="mobileNav" />
      </div>
    </NavContextProvider>
  );
};

export default Nav;
