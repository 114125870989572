import C from "constants/campaign";
import { reduceModelActions } from "utils/action";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { prepareModelAssociations } from "utils/data";
import ActionHelpers from "./helpers";
import { setOrganizationCampaigns } from "./organizationCampaign";
import { setUserCampaigns } from "./userCampaign";
import { ACTION_TYPE } from "utils/constants/action";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_CAMPAIGN_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setCampaigns = (payload) => {
  return {
    type: C.SET_CAMPAIGNS,
    payload
  };
};

export const removeCampaigns = (payload) => {
  return {
    type: C.REMOVE_CAMPAIGNS,
    payload
  };
};

export const replaceCampaigns = (payload) => {
  return {
    type: C.REPLACE_CAMPAIGNS,
    payload
  };
};

/**
 * resetCampaignNotes
 */
export function resetCampaignNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_CAMPAIGN_STORE,
      payload
    });
  };
}

export function showCampaignNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(C.UPDATE_CAMPAIGN_STORE, message || `Success!`)
    );
  };
}

export const CAMPAIGN_MODELS = ["campaigns"];

export const CAMPAIGN_ACTIONS_MAP = {
  setCampaigns,
  setOrganizationCampaigns,
  setUserCampaigns
};

/**
 * createCampaign
 */
export function createCampaign(
  { resourceID, resourceUUID, resource, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_CAMPAIGN_STORE,
        "create",
        "campaign",
        "Create campaign"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/campaigns`;

    return axios({
      method: "post",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const campaigns = [responseData];

        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          campaigns
        });

        const actions = reduceModelActions({
          keys: CAMPAIGN_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: CAMPAIGN_ACTIONS_MAP
        });

        actions.push(
          setTimeout(() => {
            dispatch(
              ActionHelpers.successNote(
                C.UPDATE_CAMPAIGN_STORE,
                "Campaign created"
              )
            );
          }, 250)
        );

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_CAMPAIGN_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * createCampaign
 */
export function createAndSendCampaign(
  { resourceID, resourceUUID, resource, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_CAMPAIGN_STORE,
        "create",
        "campaign",
        "Create and send campaign"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/campaigns/send`;

    return axios({
      method: "post",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const campaigns = [responseData];

        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          campaigns
        });

        const actions = reduceModelActions({
          keys: CAMPAIGN_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: CAMPAIGN_ACTIONS_MAP
        });

        actions.push(
          setTimeout(() => {
            dispatch(
              ActionHelpers.successNote(
                C.UPDATE_CAMPAIGN_STORE,
                "Campaign created"
              )
            );
          }, 250)
        );

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_CAMPAIGN_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * deleteCampaign
 */
export const deleteCampaign = (
  { resourceUUID, resource, uuid },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/campaigns/${uuid}`;

    return axios({
      method: "delete",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        dispatch(
          batchActions([
            removeCampaigns([responseData]),
            ActionHelpers.successNote(
              C.UPDATE_CAMPAIGN_STORE,
              "Campaign deleted"
            )
          ])
        );

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_CAMPAIGN_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * updateCampaign
 */
export function updateCampaign(
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_CAMPAIGN_STORE,
        "update",
        "campaign",
        "Update campaign"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/campaigns/${uuid}`;

    return axios({
      method: "put",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const actions = [setCampaigns([responseData])];

        actions.push(
          setTimeout(() => {
            dispatch(
              ActionHelpers.successNote(
                C.UPDATE_CAMPAIGN_STORE,
                "Campaign updated"
              )
            );
          }, 250)
        );

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_CAMPAIGN_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * fetchCampaigns
 */
export function fetchCampaigns(
  { resourceUUID, resource, resourceID, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_CAMPAIGN_STORE,
        "update",
        "campaign",
        "Fetch campaigns"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/campaigns`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      params: data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          campaigns: responseData
        });

        const actions = reduceModelActions({
          keys: CAMPAIGN_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: CAMPAIGN_ACTIONS_MAP
        });

        dispatch(batchActions(actions));
        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_CAMPAIGN_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * sendCampaign
 */
export function sendCampaign(
  { resourceUUID, resource, uuid, resourceID },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_CAMPAIGN_STORE,
        "update",
        "campaign",
        "Send campaign"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/campaigns/${uuid}/actions/send`;

    return axios({
      method: "post",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          campaigns: [responseData]
        });

        const actions = reduceModelActions({
          keys: CAMPAIGN_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: CAMPAIGN_ACTIONS_MAP
        });

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_CAMPAIGN_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}
