import React from "react";
import Pill from "components/Pill";
import classnames from "classnames";
import LoadingSpinner from "components/LoadingSpinner";

const NavLinkContent = (
  { onClick, loading, isFirst, icon, scheme, nodeTitle, callout, descriptions },
  ref
) => {
  return (
    <div ref={ref} onClick={onClick} className={"link db w-100 fl black-80"}>
      <li
        className={classnames(
          "menu__subNav--listItem ph3 pv3 flex flex-row pointer w-100 bb b--black-05 bn-l bg-white--hover",
          {
            pt3: !isFirst
          }
        )}
      >
        {icon && (
          <div className="div" style={{ height: 40, width: 40 }}>
            {icon({ scheme })}
          </div>
        )}
        <div className="flex flex-column w-100 pl3">
          <div className="flex flex-row justify-start">
            <div className="f5 fw6 color-text--primary">{nodeTitle}</div>
            {loading ? (
              <div className="pl1">
                <LoadingSpinner
                  customClasses={{ ring: "blue", size: "small" }}
                />
              </div>
            ) : null}
            {callout && (
              <Pill
                copy={callout}
                customClasses={{
                  container: "pl2",
                  copy: "f7 pa1 br2 fw5 flex-shrink-0 ph2 fw6"
                }}
              />
            )}
          </div>
          <div className="pt2">
            {descriptions.length > 0 &&
              descriptions.map((desc, descIx) => (
                <div
                  key={descIx}
                  className={classnames("f6 lh-copy", {
                    pt1: descIx > 0
                  })}
                >
                  {desc}
                </div>
              ))}
          </div>
        </div>
      </li>
    </div>
  );
};

export default React.forwardRef(NavLinkContent);
