import axios from "axios";
import CONST from "constants/user";
import { batchActions } from "redux-batched-actions";
import ActionHelpers from "./helpers";
import { logoutWithDispatch } from "actions/auth";
import { authBearerToken } from "utils/auth";
import Analytics, { USER_DELETE, LEAD_CREATE } from "utils/analytics";

/**
 * resetUserNotes
 */
export function resetUserNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: CONST.UPDATE_USER_STORE,
      payload
    });
  };
}

export function updateUser(payload) {
  return {
    type: CONST.UPDATE_USER,
    payload
  };
}

export function updateUserEducationAction(payload) {
  return {
    type: CONST.UPDATE_USER_EDUCATION,
    payload
  };
}

/**
 * removeUserManagerUsers
 */
export function removeUserManagerUsers(payload = []) {
  return {
    type: CONST.REMOVE_USER_MANAGER_USERS,
    payload
  };
}

/**
 * setUserManagerUsers
 */
export function setUserManagerUsers(payload = []) {
  return {
    type: CONST.SET_USER_MANAGER_USERS,
    payload
  };
}

/**
 * setUserApiKeys
 */
export function setUserApiKeys(payload = {}) {
  return {
    type: CONST.SET_USER_API_KEYS,
    payload
  };
}

/**
 * updateUserEducation
 */
export function updateUserEducation(userID, params, callback = () => {}) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        CONST.UPDATE_USER_STORE,
        "update",
        "user",
        "Update user education"
      )
    );

    const USERS_URL = `${process.env.NEXT_PUBLIC_API_ROOT}/v1/users/${userID}/education`;

    return axios({
      method: "put",
      url: USERS_URL,
      data: params,
      headers: authBearerToken()
    })
      .then(({ data }) => {
        const result = Array.isArray(data) ? data : [data];
        dispatch(
          batchActions([
            ActionHelpers.clearLoader(CONST.UPDATE_USER_STORE),
            updateUserEducationAction(result)
          ])
        );
        callback(null, data);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              CONST.UPDATE_USER_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * deleteUserAccount
 */
export function deleteUserAccount(userID, callback = () => {}) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        CONST.UPDATE_USER_STORE,
        "delete",
        "user",
        "Delete user"
      )
    );

    const USERS_URL = `${process.env.NEXT_PUBLIC_API_ROOT}/v1/users/${userID}`;

    return axios
      .delete(USERS_URL, { headers: authBearerToken() })
      .then(() => {
        Analytics.track(USER_DELETE);
        dispatch(ActionHelpers.clearLoader(CONST.UPDATE_USER_STORE));
        logoutWithDispatch(dispatch);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              CONST.UPDATE_USER_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * deleteManagerUser
 *
 */
export function deleteManagerUser(userID, managerUserID, callback = () => {}) {
  return (dispatch) => {
    const MANAGER_USERS_URL = `${process.env.NEXT_PUBLIC_API_ROOT}/v1/users/${userID}/manager_users/${managerUserID}`;

    return axios
      .delete(MANAGER_USERS_URL, { headers: authBearerToken() })
      .then(({ data }) => {
        dispatch(removeUserManagerUsers(data));

        const note = "Successfully removed.";
        setTimeout(() => {
          dispatch(ActionHelpers.successNote(CONST.UPDATE_USER_STORE, note));
        }, 200);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              CONST.UPDATE_USER_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * fetchManagerUsers
 *
 */
export function fetchManagerUsers(userID, callback = () => {}) {
  return (dispatch) => {
    const MANAGER_USERS_URL = `${process.env.NEXT_PUBLIC_API_ROOT}/v1/users/${userID}/manager_users`;

    return axios
      .get(MANAGER_USERS_URL, { headers: authBearerToken() })
      .then(({ data }) => {
        dispatch(setUserManagerUsers(data));
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              CONST.UPDATE_USER_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * createUserLead
 * @param {Object} lead - lead object to save in db
 * @param {String} lead.email - user email associated with lead
 * @param {Object} lead.preferences - object containing the preferences
 * @param {String} lead.preferences.feature - NOMINATE
 * @param {String} lead.preferences.context - ALL
 * @param {Object} lead.preferences.details - extra unstructured data
 * @param {function} callback
 */
const DEFAULT_LEAD_NOTE = "Successfully joined the waitlist.";

export const createUserLead = (
  { lead, note = DEFAULT_LEAD_NOTE },
  callback = () => {}
) => {
  return (dispatch) => {
    const USERS_LEAD_URL = `${process.env.NEXT_PUBLIC_API_ROOT}/v1/leads`;

    return axios({
      method: "post",
      url: USERS_LEAD_URL,
      data: lead
    })
      .then(() => {
        Analytics.track(LEAD_CREATE, {
          feature: lead.preferences.feature,
          context: lead.preferences.context,
          email: lead.email
        });

        setTimeout(() => {
          dispatch(ActionHelpers.successNote(CONST.UPDATE_USER_STORE, note));
        }, 200);

        callback();
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              CONST.UPDATE_USER_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};
