import C from "constants/contactlessConfig";
import { reduceModelActions } from "utils/action";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { prepareModelAssociations } from "utils/data";
import ActionHelpers from "./helpers";
import { setOrganizationContactlessConfigs } from "./organizationContactlessConfig";
import { setUserContactlessConfigs } from "./userContactlessConfig";
import { ACTION_TYPE } from "utils/constants/action";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_CONTACTLESS_CONFIG_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setContactlessConfigs = (payload) => {
  return {
    type: C.SET_CONTACTLESS_CONFIGS,
    payload
  };
};

export const removeContactlessConfigs = (payload) => {
  return {
    type: C.REMOVE_CONTACTLESS_CONFIGS,
    payload
  };
};

export const replaceContactlessConfigs = (payload) => {
  return {
    type: C.REPLACE_CONTACTLESS_CONFIGS,
    payload
  };
};

/**
 * resetContactlessConfigNotes
 */
export function resetContactlessConfigNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_CONTACTLESS_CONFIG_STORE,
      payload
    });
  };
}

export function showContactlessConfigNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_CONTACTLESS_CONFIG_STORE,
        message || `Success!`
      )
    );
  };
}

export const CONTACTLESS_CONFIG_MODELS = ["contactlessConfigs"];

export const CONTACTLESS_CONFIG_ACTIONS_MAP = {
  setContactlessConfigs,
  setOrganizationContactlessConfigs,
  setUserContactlessConfigs
};

/**
 * updateContactlessConfig
 */
export function updateContactlessConfig(
  { resourceUUID, resource, resourceID, uuid, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_CONTACTLESS_CONFIG_STORE,
        "update",
        "contactlessConfig",
        "Update contactlessConfig"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/contactless-configs/${uuid}`;

    return axios({
      method: "put",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          contactlessConfigs: [responseData]
        });

        const actions = reduceModelActions({
          keys: CONTACTLESS_CONFIG_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: CONTACTLESS_CONFIG_ACTIONS_MAP
        });

        actions.push(
          setTimeout(() => {
            dispatch(
              ActionHelpers.successNote(
                C.UPDATE_CONTACTLESS_CONFIG_STORE,
                "Contactless design updated"
              )
            );
          }, 250)
        );

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_CONTACTLESS_CONFIG_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * fetchContactlessConfigs
 */
export function fetchContactlessConfigs(
  { resourceUUID, resource, resourceID, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_CONTACTLESS_CONFIG_STORE,
        "update",
        "contactlessConfig",
        "Fetch contactlessConfigs"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/contactless-configs`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          contactlessConfigs: responseData
        });

        const actions = reduceModelActions({
          keys: CONTACTLESS_CONFIG_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: CONTACTLESS_CONFIG_ACTIONS_MAP
        });

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_CONTACTLESS_CONFIG_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}
