import C from "constants/organizationWebhook";
import ActionHelpers from "./helpers";

export function setLoading() {
  return {
    type: C.UPDATE_ORGANIZATION_WEBHOOK_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setOrganizationWebhooks = (payload) => {
  return {
    type: C.SET_ORGANIZATION_WEBHOOKS,
    payload
  };
};

export const replaceOrganizationWebhooks = (payload) => {
  return {
    type: C.REPLACE_ORGANIZATION_WEBHOOKS,
    payload
  };
};

/**
 * resetOrganizationWebhookNotes
 */
export function resetOrganizationWebhookNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_ORGANIZATION_WEBHOOK_STORE,
      payload
    });
  };
}

export function showOrganizationWebhookNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_ORGANIZATION_WEBHOOK_STORE,
        message || `Success!`
      )
    );
  };
}
