import React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"navIcon--coupons"}</title>
      <g fill="none" fillRule="evenodd">
        <circle fill="#A6C0FF" cx={13} cy={9} r={2} />
        <circle fill="#A6C0FF" cx={13} cy={14.4} r={2} />
        <circle fill="#A6C0FF" cx={13} cy={20.3} r={2} />
        <circle fill="#A6C0FF" cx={13} cy={26.1} r={2} />
        <circle fill="#A6C0FF" cx={13} cy={32} r={2} />
        <path
          d="M38 24.047h-1.253c-2.026 0-3.684-1.588-3.684-3.53 0-1.94 1.658-3.529 3.684-3.529H38V7H3v27h35v-9.953zM5.505 31.6V9.435h29.99v5.26c-2.837.564-4.974 2.964-4.974 5.823 0 2.858 2.137 5.258 4.974 5.823V31.6H5.505z"
          fill="#5D8BFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
