import C from "constants/datasource";
import { reduceModelActions } from "utils/action";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { prepareModelAssociations } from "utils/data";
import ActionHelpers from "./helpers";
import { setOrganizationDatasources } from "./organizationDatasource";
import { setUserDatasources } from "./userDatasource";
import { ACTION_TYPE } from "utils/constants/action";
import { apiResourcePath } from "utils/paths";
import { setAttachments } from "./attachment";
import { setOrganizationAttachments } from "./organizationAttachment";
import { setUserAttachments } from "./userAttachment";

export function setLoading() {
  return {
    type: C.UPDATE_DATASOURCE_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setDatasources = (payload) => {
  return {
    type: C.SET_DATASOURCES,
    payload
  };
};

export const removeDatasources = (payload) => {
  return {
    type: C.REMOVE_DATASOURCES,
    payload
  };
};

export const replaceDatasources = (payload) => {
  return {
    type: C.REPLACE_DATASOURCES,
    payload
  };
};

/**
 * resetDatasourceNotes
 */
export function resetDatasourceNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_DATASOURCE_STORE,
      payload
    });
  };
}

export function showDatasourceNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_DATASOURCE_STORE,
        message || `Success!`
      )
    );
  };
}

export const RESOURCE_MODELS = ["datasources", "attachments"];

export const RESOURCE_ACTIONS_MAP = {
  setDatasources,
  setUserDatasources,
  setOrganizationDatasources,
  setAttachments,
  setOrganizationAttachments,
  setUserAttachments
};

/**
 * createDatasource
 */
export function createDatasource(
  { resourceID, resourceUUID, resource, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_DATASOURCE_STORE,
        "create",
        "datasource",
        "Create datasource"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/datasources`;

    return axios({
      method: "post",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const { attachment, ...datasource } = responseData;
        const datasources = [datasource];
        const attachments = attachment ? [attachment] : [];

        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          datasources,
          attachments
        });

        const actions = reduceModelActions({
          keys: RESOURCE_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: RESOURCE_ACTIONS_MAP
        });

        actions.push(
          setTimeout(() => {
            dispatch(
              ActionHelpers.successNote(
                C.UPDATE_DATASOURCE_STORE,
                "Datasource created"
              )
            );
          }, 250)
        );

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_DATASOURCE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * deleteDatasource
 */
export const deleteDatasource = (
  { resourceUUID, resource, uuid },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/datasources/${uuid}`;
    return axios({
      method: "delete",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        dispatch(
          batchActions([
            removeDatasources([responseData]),
            ActionHelpers.successNote(
              C.UPDATE_DATASOURCE_STORE,
              "Datasource deleted"
            )
          ])
        );

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_DATASOURCE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * updateDatasource
 */
export function updateDatasource(
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_DATASOURCE_STORE,
        "update",
        "datasource",
        "Update datasource"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/datasources/${uuid}`;

    return axios({
      method: "put",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const actions = [setDatasources([responseData])];

        actions.push(
          setTimeout(() => {
            dispatch(
              ActionHelpers.successNote(
                C.UPDATE_DATASOURCE_STORE,
                "Datasource updated"
              )
            );
          }, 250)
        );

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_DATASOURCE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * fetchDatasources
 */
export function fetchDatasources(
  { resourceUUID, resource, resourceID, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_DATASOURCE_STORE,
        "update",
        "datasource",
        "Fetch datasources"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/datasources`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          datasources: responseData
        });

        const actions = reduceModelActions({
          keys: RESOURCE_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: RESOURCE_ACTIONS_MAP
        });

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_DATASOURCE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}
