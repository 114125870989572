import C from "constants/editableContent";
import { reduceModelActions } from "utils/action";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { prepareModelAssociations } from "utils/data";
import ActionHelpers from "./helpers";
import { setOrganizationEditableContents } from "./organizationEditableContent";
import { setUserEditableContents } from "./userEditableContent";
import { ACTION_TYPE } from "utils/constants/action";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_EDITABLE_CONTENT_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setEditableContents = (payload) => {
  return {
    type: C.SET_EDITABLE_CONTENTS,
    payload
  };
};

export const removeEditableContents = (payload) => {
  return {
    type: C.REMOVE_EDITABLE_CONTENTS,
    payload
  };
};

export const replaceEditableContents = (payload) => {
  return {
    type: C.REPLACE_EDITABLE_CONTENTS,
    payload
  };
};

/**
 * resetEditableContentNotes
 */
export function resetEditableContentNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_EDITABLE_CONTENT_STORE,
      payload
    });
  };
}

export function showEditableContentNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(
        C.UPDATE_EDITABLE_CONTENT_STORE,
        message || `Success!`
      )
    );
  };
}

export const EDITABLE_CONTENT_MODELS = ["editableContents"];

export const EDITABLE_CONTENT_ACTIONS_MAP = {
  setEditableContents,
  setOrganizationEditableContents,
  setUserEditableContents
};

/**
 * createEditableContent
 */
export function createEditableContent(
  { resourceID, resourceUUID, resource, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_EDITABLE_CONTENT_STORE,
        "create",
        "editableContent",
        "Create editableContent"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/editable-contents`;

    return axios({
      method: "post",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const editableContents = [responseData];

        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          editableContents
        });

        const actions = reduceModelActions({
          keys: EDITABLE_CONTENT_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: EDITABLE_CONTENT_ACTIONS_MAP
        });

        actions.push(
          setTimeout(() => {
            dispatch(
              ActionHelpers.successNote(
                C.UPDATE_EDITABLE_CONTENT_STORE,
                "EditableContent created"
              )
            );
          }, 250)
        );

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_EDITABLE_CONTENT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * deleteEditableContent
 */
export const deleteEditableContent = (
  { resourceUUID, resource, uuid },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/editable-contents/${uuid}`;

    return axios({
      method: "delete",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        dispatch(
          batchActions([
            removeEditableContents([responseData]),
            ActionHelpers.successNote(
              C.UPDATE_EDITABLE_CONTENT_STORE,
              "EditableContent deleted"
            )
          ])
        );

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_EDITABLE_CONTENT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * updateEditableContent
 */
export function updateEditableContent(
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_EDITABLE_CONTENT_STORE,
        "update",
        "editableContent",
        "Update editableContent"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/editable-contents/${uuid}`;

    return axios({
      method: "put",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const actions = [setEditableContents([responseData])];

        actions.push(
          setTimeout(() => {
            dispatch(
              ActionHelpers.successNote(
                C.UPDATE_EDITABLE_CONTENT_STORE,
                "EditableContent updated"
              )
            );
          }, 250)
        );

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_EDITABLE_CONTENT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * fetchEditableContents
 */
export function fetchEditableContents(
  { resourceUUID, resource, resourceID, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_EDITABLE_CONTENT_STORE,
        "update",
        "editableContent",
        "Fetch editableContents"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/editable-contents`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      params: data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          editableContents: responseData
        });

        const actions = reduceModelActions({
          keys: EDITABLE_CONTENT_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: EDITABLE_CONTENT_ACTIONS_MAP
        });

        dispatch(batchActions(actions));
        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_EDITABLE_CONTENT_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}
