import React from "react";

const QRCode = (props) => {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"navIcon--qrCode"}</title>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#5D8BFF"
          strokeWidth={1.436}
          d="M5.197 5.197h9.576v9.576H5.197zM5.197 24.828h9.576v9.576H5.197zM24.828 5.197h9.576v9.576h-9.576z"
        />
        <path
          fill="#5D8BFF"
          fillRule="nonzero"
          d="M7.591 7.591h4.788v4.788H7.591zM27.222 7.591h4.788v4.788h-4.788zM7.591 27.222h4.788v4.788H7.591zM4.239 16.209h2.394v2.394H4.239zM6.633 18.603h2.394v2.394H6.633zM9.027 16.209h2.394v2.394H9.027zM11.421 20.997h2.394v2.394h-2.394zM4.239 20.997h2.394v2.394H4.239zM13.815 18.603h2.394v2.394h-2.394zM16.209 16.209h2.394v2.394h-2.394zM18.603 13.815h2.394v2.394h-2.394zM16.209 11.421h2.394v2.394h-2.394zM20.997 11.421h2.394v2.394h-2.394zM18.603 9.027h2.394v2.394h-2.394zM20.997 6.633h2.394v2.394h-2.394zM18.603 4.239h2.394v2.394h-2.394zM16.209 6.633h2.394v2.394h-2.394zM32.967 16.209h2.394v2.394h-2.394zM28.179 16.209h2.394v2.394h-2.394zM20.997 18.603h2.394v2.394h-2.394zM30.573 18.603h2.394v2.394h-2.394zM25.785 18.603h2.394v2.394h-2.394zM23.391 20.997h2.394v2.394h-2.394zM18.603 20.997h2.394v2.394h-2.394zM20.997 25.785h2.394v2.394h-2.394zM16.209 25.785h2.394v2.394h-2.394zM20.997 30.573h2.394v2.394h-2.394zM16.209 30.573h2.394v2.394h-2.394zM18.603 28.179h2.394v2.394h-2.394zM18.603 32.967h2.394v2.394h-2.394zM25.785 25.785h2.394v2.394h-2.394z"
        />
        <path
          fill="#5D8BFF"
          fillRule="nonzero"
          d="M28.179 28.179h2.394v2.394h-2.394zM28.179 32.967h2.394v2.394h-2.394zM25.785 30.573h2.394v2.394h-2.394zM32.967 25.785h2.394v2.394h-2.394zM32.967 20.997h2.394v2.394h-2.394zM32.967 32.967h2.394v2.394h-2.394zM30.573 30.573h2.394v2.394h-2.394zM30.573 23.391h2.394v2.394h-2.394z"
        />
      </g>
    </svg>
  );
};

export default QRCode;
