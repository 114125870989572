import PATHS from "utils/paths";
import BrandIcon from "components/Icons/brand";
import { AuthedPricingHOC } from "./hocs";
import kebabCase from "lodash/kebabCase";
import {
  /**
   * Unauthenticated nav config
   */
  UNAUTHED_PRICING,
  /**
   * Authenticated nav config
   */
  AUTHED_PROFILE,
  AUTHED_PRICING,
  AUTHED_BILLING,
  AUTHED_HOME,
  AUTHED_SALES,
  AUTHED_LINKS,
  AUTHED_CONTACTLESS,
  AUTHED_PAGES,
  AUTHED_PRODUCTS,
  AUTHED_SETTINGS,
  AUTHED_COUPONS,
  AUTHED_ANALYTICS,
  AUTHED_TAX_RATES,
  AUTHED_SHIPPING_RATES,
  AUTHED_FILES,
  AUTHED_CAMPAIGNS,
  UNAUTHED_LINKS,
  UNAUTHED_LINKS_API,
  UNAUTHED_LINKS_ADVANCED,
  UNAUTHED_CHECKOUT,
  UNAUTHED_CONTACTLESS,
  UNAUTHED_DIGITAL_CONTENT,
  // UNAUTHED_SUBSCRIPTIONS,
  // UNAUTHED_CREATORS,
  UNAUTHED_TUTORIALS,
  UNAUTHED_DOCS,
  UNAUTHED_LEARN,
  UNAUTHED_CHANNELS,
  UNAUTHED_STRIPE,
  UNAUTHED_ZAPIER,
  UNAUTHED_WEBFLOW,
  UNAUTHED_ANALYTICS,
  UNAUTHED_PAGES
} from "./config";
import { HUB_APP_SUB_SECTION } from "utils/constants/navigation";
import { getJWT } from "utils/auth";

const NAV_SECTION_IDS = {
  PROFILE: "profile",
  LINKS: "links",
  PAGES: "pages",
  PRICING: "pricing",
  RESOURCES: "resources",
  TUTORIALS: "tutorials",
  LEARN: "learn",
  INTEGRATIONS: "integrations",
  DOCS: "docs"
};

const DEFAULT_STYLE = { highlight: true };

const tutorialGroup = {
  style: DEFAULT_STYLE,
  label: "Tutorials",
  href: PATHS.TUTORIALS,
  zone: PATHS.TUTORIALS,
  sectionId: NAV_SECTION_IDS.TUTORIALS,
  subnav: {
    title: "Tutorials Overview",
    nodes: [UNAUTHED_TUTORIALS]
  }
};

const learnGroup = {
  style: DEFAULT_STYLE,
  label: "Learn",
  href: PATHS.LEARN,
  zone: PATHS.LEARN,
  sectionId: NAV_SECTION_IDS.LEARN,
  subnav: {
    title: "Learn Overview",
    nodes: [UNAUTHED_LEARN]
  }
};

const docsGroup = {
  style: DEFAULT_STYLE,
  label: "Docs",
  href: PATHS.DOCS,
  zone: PATHS.DOCS,
  sectionId: NAV_SECTION_IDS.DOCS,
  subnav: {
    title: "Docs Overview",
    nodes: [UNAUTHED_DOCS]
  }
};

const integrationsGroup = {
  style: DEFAULT_STYLE,
  label: "Integrations",
  href: PATHS.FEATURES_INTEGRATIONS_STRIPE,
  sectionId: NAV_SECTION_IDS.INTEGRATIONS,
  subnav: {
    title: "Integrations Overview",
    nodes: [UNAUTHED_STRIPE, UNAUTHED_ZAPIER, UNAUTHED_WEBFLOW]
  }
};

export const unauthedRoutes = () => {
  return [
    {
      href: getJWT() ? PATHS.HUB : PATHS.HOME,
      icon: BrandIcon,
      style: {
        ...DEFAULT_STYLE,
        highlight: false
      }
    },
    {
      style: DEFAULT_STYLE,
      label: "Payment Pages",
      href: PATHS.BUILDER_APPLICATIONS,
      sectionId: NAV_SECTION_IDS.PAGES,
      subnav: {
        title: "Payment Pages Overview",
        nodes: [UNAUTHED_PAGES]
      }
    },
    {
      style: DEFAULT_STYLE,
      label: "Payment Links",
      href: PATHS.FEATURES_LINKS_BUILDER,
      sectionId: NAV_SECTION_IDS.LINKS,
      subnav: {
        title: "Payment Links Overview",
        nodes: [
          UNAUTHED_LINKS_API,
          UNAUTHED_LINKS_ADVANCED,
          UNAUTHED_LINKS,
          UNAUTHED_CONTACTLESS,
          UNAUTHED_DIGITAL_CONTENT,
          UNAUTHED_CHANNELS,
          UNAUTHED_CHECKOUT,
          UNAUTHED_ANALYTICS
        ]
      }
    },
    // {
    //   style: DEFAULT_STYLE,
    //   label: "Payment Pages",
    //   href: PATHS.FEATURES_PAGES_SUBSCRIPTIONS,
    //   sectionId: NAV_SECTION_IDS.PAGES,
    //   subnav: {
    //     title: "Payment Pages Overview",
    //     nodes: [UNAUTHED_SUBSCRIPTIONS, UNAUTHED_CREATORS]
    //   }
    // },
    {
      style: DEFAULT_STYLE,
      label: "Pricing",
      href: PATHS.PRICING,
      sectionId: NAV_SECTION_IDS.PRICING,
      subnav: {
        title: "Pricing Overview",
        nodes: [UNAUTHED_PRICING]
      }
    },
    integrationsGroup,
    tutorialGroup,
    learnGroup,
    docsGroup
    // {
    //   style: DEFAULT_STYLE,
    //   label: "Plan",
    //   sectionId: PATHS.PLAN,
    //   subnav: {
    //     title: "PLAN OVERVIEW",
    //     nodes: compact([
    //       UNAUTHED_PLAN,
    //       UNAUTHED_LEVELS,
    //       UNAUTHED_PRODUCTS,
    //       flags.app.evaluate && UNAUTHED_EVALUATE
    //     ])
    //   }
    // },
    // {
    //   style: DEFAULT_STYLE,
    //   label: "Documentation",
    //   sectionId: PATHS.MARKETPLACE,
    //   subnav: {
    //     title: "Resources Overview",
    //     nodes: [UNAUTHED_MARKETPLACE, UNAUTHED_SUGGESTIONS]
    //   }
    // },
    // {
    //   style: DEFAULT_STYLE,
    //   label: "Team",
    //   sectionId: PATHS.TEAM,
    //   callout: "New",
    //   subnav: {
    //     title: "Team Overview",
    //     nodes: [UNAUTHED_TEAM]
    //   }
    // }
  ];
};

export const authedRoutes = ({ route, isDesktop, appRoot, userFeatures }) => {
  let nodes = [];
  if (route !== PATHS.ONBOARDING) {
    // const flags = capabilityFlags(capabilities);
    const hubGroup = {
      href: PATHS.HUB,
      icon: BrandIcon,
      style: {
        ...DEFAULT_STYLE,
        highlight: false
      }
    };
    const pricingGroup = {
      style: DEFAULT_STYLE,
      label: "Pricing",
      hoc: AuthedPricingHOC,
      sectionId: NAV_SECTION_IDS.PRICING,
      subnav: {
        title: "Pricing Overview",
        nodes: [AUTHED_PRICING]
      }
    };
    const accountGroup = {
      style: DEFAULT_STYLE,
      label: "Account",
      href: PATHS.PROFILE,
      sectionId: NAV_SECTION_IDS.PROFILE,
      subnav: {
        title: "Account Overview",
        nodes: [
          AUTHED_PROFILE,
          AUTHED_BILLING,
          AUTHED_SETTINGS({
            href: `${appRoot}/${HUB_APP_SUB_SECTION.SETTINGS}`
          })
        ]
      }
    };

    const pageNodes = [
      AUTHED_HOME({ href: `${appRoot}/${HUB_APP_SUB_SECTION.HOME}` }),
      AUTHED_SALES({ href: `${appRoot}/${HUB_APP_SUB_SECTION.SALES}` }),
      AUTHED_LINKS({ href: `${appRoot}/${HUB_APP_SUB_SECTION.LINKS}` }),
      AUTHED_CONTACTLESS({
        href: `${appRoot}/${HUB_APP_SUB_SECTION.CONTACTLESS}`
      }),
      AUTHED_PRODUCTS({
        href: `${appRoot}/${HUB_APP_SUB_SECTION.PRODUCTS}`
      }),
      AUTHED_COUPONS({
        href: `${appRoot}/${HUB_APP_SUB_SECTION.COUPONS}`
      }),
      AUTHED_TAX_RATES({
        href: `${appRoot}/${kebabCase(HUB_APP_SUB_SECTION.TAX_RATES)}`
      }),
      AUTHED_ANALYTICS({
        href: `${appRoot}/${HUB_APP_SUB_SECTION.ANALYTICS}`
      }),
      AUTHED_FILES({
        href: `${appRoot}/${HUB_APP_SUB_SECTION.FILES}`
      })
    ];

    const allPageNodes = [...pageNodes];
    if (userFeatures.pages) {
      allPageNodes.push(
        AUTHED_PAGES({
          href: `${appRoot}/${HUB_APP_SUB_SECTION.PAGES}`
        })
      );
    }
    if (userFeatures.campaigns) {
      allPageNodes.push(
        AUTHED_CAMPAIGNS({
          href: `${appRoot}/${HUB_APP_SUB_SECTION.CAMPAIGNS}`
        })
      );
    }
    if (userFeatures.shipping_rates) {
      allPageNodes.push(
        AUTHED_SHIPPING_RATES({
          href: `${appRoot}/${kebabCase(HUB_APP_SUB_SECTION.SHIPPING_RATES)}`
        })
      );
    }
    // Insights Nav TBD
    // if (userFeatures.insights) {
    //   allPageNodes.push(
    //     AUTHED_INSIGHTS({
    //       href: `${appRoot}/${HUB_APP_SUB_SECTION.INSIGHTS}`
    //     })
    //   );
    // }

    if (isDesktop) {
      nodes = [
        hubGroup,
        {
          style: DEFAULT_STYLE,
          label: "Pages",
          href: `${appRoot}/${HUB_APP_SUB_SECTION.HOME}`,
          sectionId: NAV_SECTION_IDS.HOME,
          subnav: {
            title: "Pages Overview",
            nodes: allPageNodes
          }
        },
        pricingGroup,
        accountGroup,
        tutorialGroup,
        learnGroup,
        docsGroup
      ];
    } else {
      nodes = [
        hubGroup,
        {
          style: DEFAULT_STYLE,
          label: "Pages",
          href: `${appRoot}/${HUB_APP_SUB_SECTION.HOME}`,
          sectionId: HUB_APP_SUB_SECTION.HOME,
          subnav: {
            title: "Sections Overview",
            nodes: allPageNodes
          }
        },
        pricingGroup,
        accountGroup,
        tutorialGroup,
        learnGroup,
        docsGroup
      ];
    }
  }

  return nodes;
};
