import C from "constants/audience";
import { reduceModelActions } from "utils/action";
import axios from "axios";
import { batchActions } from "redux-batched-actions";
import { authBearerToken } from "utils/auth";
import { prepareModelAssociations } from "utils/data";
import ActionHelpers from "./helpers";
import { setOrganizationAudiences } from "./organizationAudience";
import { setUserAudiences } from "./userAudience";
import { ACTION_TYPE } from "utils/constants/action";
import { apiResourcePath } from "utils/paths";

export function setLoading() {
  return {
    type: C.UPDATE_AUDIENCE_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setAudiences = (payload) => {
  return {
    type: C.SET_AUDIENCES,
    payload
  };
};

export const removeAudiences = (payload) => {
  return {
    type: C.REMOVE_AUDIENCES,
    payload
  };
};

export const replaceAudiences = (payload) => {
  return {
    type: C.REPLACE_AUDIENCES,
    payload
  };
};

/**
 * resetAudienceNotes
 */
export function resetAudienceNotes(payload) {
  return (dispatch) => {
    dispatch({
      type: C.UPDATE_AUDIENCE_STORE,
      payload
    });
  };
}

export function showAudienceNote(message) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.successNote(C.UPDATE_AUDIENCE_STORE, message || `Success!`)
    );
  };
}

export const AUDIENCE_MODELS = ["audiences"];

export const AUDIENCE_ACTIONS_MAP = {
  setAudiences,
  setOrganizationAudiences,
  setUserAudiences
};

/**
 * createAudience
 */
export function createAudience(
  { resourceID, resourceUUID, resource, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_AUDIENCE_STORE,
        "create",
        "audience",
        "Create audience"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/audiences`;

    return axios({
      method: "post",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const audiences = [responseData];

        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          audiences
        });

        const actions = reduceModelActions({
          keys: AUDIENCE_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: AUDIENCE_ACTIONS_MAP
        });

        actions.push(
          setTimeout(() => {
            dispatch(
              ActionHelpers.successNote(
                C.UPDATE_AUDIENCE_STORE,
                "Audience created"
              )
            );
          }, 250)
        );

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_AUDIENCE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * deleteAudience
 */
export const deleteAudience = (
  { resourceUUID, resource, uuid },
  callback = () => {}
) => {
  return (dispatch) => {
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/audiences/${uuid}`;
    return axios({
      method: "delete",
      url: REQUEST_URL,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        dispatch(
          batchActions([
            removeAudiences([responseData]),
            ActionHelpers.successNote(
              C.UPDATE_AUDIENCE_STORE,
              "Audience deleted"
            )
          ])
        );

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_AUDIENCE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
};

/**
 * updateAudience
 */
export function updateAudience(
  { resourceUUID, resource, uuid, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_AUDIENCE_STORE,
        "update",
        "audience",
        "Update audience"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/audiences/${uuid}`;

    return axios({
      method: "put",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const actions = [setAudiences([responseData])];

        actions.push(
          setTimeout(() => {
            dispatch(
              ActionHelpers.successNote(
                C.UPDATE_AUDIENCE_STORE,
                "Audience updated"
              )
            );
          }, 250)
        );

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_AUDIENCE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 * fetchAudiences
 */
export function fetchAudiences(
  { resourceUUID, resource, resourceID, data },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_AUDIENCE_STORE,
        "update",
        "audience",
        "Fetch audiences"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/audiences`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      data,
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        const preparedResponse = prepareModelAssociations({
          resource,
          resourceID,
          audiences: responseData
        });

        const actions = reduceModelActions({
          keys: AUDIENCE_MODELS,
          action: ACTION_TYPE.SET,
          resource,
          data: preparedResponse,
          actionsMap: AUDIENCE_ACTIONS_MAP
        });

        dispatch(batchActions(actions));

        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_AUDIENCE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}

/**
 *
 */
export function fetchRawAudienceFromDatasources(
  { resourceUUID, resource, datasources },
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(
      ActionHelpers.loadingAction(
        C.UPDATE_AUDIENCE_STORE,
        "update",
        "audience",
        "Fetch audience send config"
      )
    );
    const REQUEST_URL = `${apiResourcePath({
      resource,
      resourceUUID
    })}/audiences/raw`;

    return axios({
      method: "get",
      url: REQUEST_URL,
      params: {
        datasources
      },
      headers: authBearerToken()
    })
      .then(({ data: responseData }) => {
        callback(null, responseData);
      })
      .catch((err) => {
        dispatch(
          batchActions(
            ActionHelpers.errorAndClear(
              C.UPDATE_AUDIENCE_STORE,
              err,
              false,
              dispatch
            )
          )
        );
        callback(err);
      });
  };
}
