import React from "react";

const Campaigns = (props) => {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"navIcon--campaigns"}</title>
      <path
        d="M10.236 25.857l9.16 1.923c0 1.922-2.63 5.23-6.094 3.8-2.31-.954-3.331-2.862-3.066-5.723h0zM36.964 8.735A1 1 0 0137 9v21.44a1 1 0 01-1.218.976l-32-7.144A1 1 0 013 23.296v-5.493a1 1 0 01.735-.964l32-8.803a1 1 0 011.23.699z"
        stroke="#5D8BFF"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Campaigns;
