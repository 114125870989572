import React from "react";

const Stripe = (props) => (
  <svg
    width={40}
    height={40}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{"stripe_icon"}</title>
    <defs>
      <filter
        x="-20.7%"
        y="-18.1%"
        width="141.4%"
        height="141.4%"
        filterUnits="objectBoundingBox"
        id="a"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2.5}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0.816578741 0 0 0 0 0.816578741 0 0 0 0 0.816578741 0 0 0 0.571005655 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect id="b" x={0.667} y={0.667} width={38.667} height={38.667} rx={10} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M0 0h40v40H0z" />
      <use fill="#000" filter="url(#a)" xlinkHref="#b" />
      <use fill="#635BFF" xlinkHref="#b" />
      <g fill="#FFF">
        <path d="M37.323 20.324c0-2.43-1.175-4.348-3.422-4.348-2.256 0-3.621 1.918-3.621 4.33 0 2.857 1.612 4.3 3.924 4.3 1.129 0 1.982-.256 2.626-.617V22.09c-.644.323-1.384.523-2.322.523-.92 0-1.735-.323-1.84-1.444h4.636c0-.123.02-.617.02-.845Zm-4.683-.902c0-1.072.654-1.519 1.252-1.519.578 0 1.194.447 1.194 1.52H32.64ZM26.533 16.075c-.934 0-1.535.436-1.868.74l-.124-.588h-2.098v11.058l2.384-.503.01-2.684c.342.247.848.598 1.687.598 1.706 0 3.26-1.366 3.26-4.372-.01-2.75-1.583-4.249-3.25-4.249Zm-.572 6.534c-.562 0-.896-.199-1.125-.445l-.01-3.519c.248-.275.592-.464 1.135-.464.868 0 1.468.967 1.468 2.21 0 1.27-.59 2.218-1.468 2.218ZM19.17 15.48l2.38-.521V13l-2.38.511zM19.17 16.174h2.381v8.333H19.17zM16.605 16.878l-.152-.704h-2.045v8.333h2.367V18.86c.559-.733 1.506-.6 1.8-.495v-2.19c-.304-.115-1.411-.324-1.97.704ZM11.9 14.091l-2.343.497-.01 7.66c0 1.415 1.066 2.458 2.487 2.458.787 0 1.363-.144 1.68-.316v-1.941c-.308.124-1.824.564-1.824-.851v-3.395h1.824v-2.037H11.89l.01-2.075ZM5.411 18.645c0-.37.305-.512.81-.512.724 0 1.639.218 2.363.607v-2.229a6.311 6.311 0 0 0-2.363-.436C4.286 16.075 3 17.08 3 18.76c0 2.618 3.621 2.2 3.621 3.329 0 .436-.381.579-.915.579-.79 0-1.8-.323-2.601-.76v2.258c.886.38 1.782.54 2.601.54 1.982 0 3.345-.976 3.345-2.674-.01-2.826-3.64-2.323-3.64-3.386Z" />
      </g>
    </g>
  </svg>
);

export default Stripe;
