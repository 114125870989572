import React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"navIcon--house"}</title>
      <path
        d="M30.318 35h-8.016V24.448h-3.604V35h-8.016C8.652 35 7 33.417 7 31.47V16.04L20.5 4 34 16.04v15.43c0 1.947-1.652 3.53-3.682 3.53zm-5.254-2.647h5.254c.508 0 .92-.396.92-.882V17.193L20.5 7.616 9.761 17.193V31.47c0 .487.413.882.92.882h5.255V21.801h9.128v10.552z"
        fill="#5D8BFF"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgComponent;
