import React from "react";

function SvgComponent(props) {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
      <title>{"navIcon--code"}</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M30.44 14.56a1.5 1.5 0 012.12-2.12l6 6a1.5 1.5 0 010 2.12l-6 6a1.5 1.5 0 01-2.12-2.12l4.939-4.94-4.94-4.94zM4.621 19.5l4.94 4.94a1.5 1.5 0 01-2.122 2.12l-6-6a1.5 1.5 0 010-2.12l6-6a1.5 1.5 0 012.122 2.12L4.62 19.5z"
          fill="#5D8BFF"
        />
        <path
          d="M23.696 9.418a1.5 1.5 0 112.809 1.054l-9.58 20.038a1.5 1.5 0 11-2.808-1.054l9.58-20.038z"
          fill="#A6C0FF"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
