import React from "react";

function SvgComponent(props) {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
      <title>{"navIcon--account"}</title>
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <rect
          fill="#A6C0FF"
          x={17.882}
          y={0.48}
          width={2.235}
          height={36.568}
          rx={1.118}
        />
        <rect
          fill="#A6C0FF"
          x={31.294}
          y={0.48}
          width={2.235}
          height={36.568}
          rx={1.118}
        />
        <rect
          fill="#A6C0FF"
          x={4.471}
          y={0.48}
          width={2.235}
          height={36.568}
          rx={1.118}
        />
        <ellipse
          stroke="#5D8BFF"
          strokeWidth={2}
          fill="#FFF"
          cx={19}
          cy={23.886}
          rx={4.588}
          ry={4.429}
        />
        <ellipse
          stroke="#5D8BFF"
          strokeWidth={2}
          fill="#FFF"
          cx={32.412}
          cy={14.114}
          rx={4.588}
          ry={4.429}
        />
        <ellipse
          stroke="#5D8BFF"
          strokeWidth={2}
          fill="#FFF"
          cx={5.588}
          cy={10.857}
          rx={4.588}
          ry={4.429}
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
