import React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"navIcon--analytics"}</title>
      <g fill="none" fillRule="evenodd">
        <path fill="#A6C0FF" d="M2 33h36v2H2z" />
        <path
          d="M6.875 30h5.25c.483 0 .875-.298.875-.667v-6.666c0-.369-.392-.667-.875-.667h-5.25c-.483 0-.875.298-.875.667v6.666c0 .369.392.667.875.667zm.875-6.667h3.5v5.334h-3.5v-5.334zM16.875 14c-.483 0-.875.448-.875 1v14c0 .552.392 1 .875 1h5.25c.483 0 .875-.448.875-1V15c0-.552-.392-1-.875-1h-5.25zm4.375 14h-3.5V16h3.5v12z"
          fill="#A6C0FF"
          fillRule="nonzero"
        />
        <path
          d="M32.125 4h-5.25C26.392 4 26 4.582 26 5.3v23.4c0 .718.392 1.3.875 1.3h5.25c.483 0 .875-.582.875-1.3V5.3c0-.718-.392-1.3-.875-1.3zm-.875 23.4h-3.5V6.6h3.5v20.8z"
          fill="#5D8BFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
