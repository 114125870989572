import React from "react";
import PropTypes from "prop-types";
import { SLIM_PILL_CLASSES, STATUS } from "utils/constants/ui";
import { NO_OP } from "utils/action";
import classnames from "classnames";

const DEFAULT_CLASSES = {
  container: "flex w-100 items-end justify-end mv2",
  copy: "f7 pa2 br2 fw6 flex-shrink-0 items-center"
};

export const MIN_PILL_CLASSES = {
  container: "",
  copy: "f7 pa1 br2 fw6 items-center dib"
};

const PILL_STATUS_CLASSES = {
  [STATUS.DEFAULT]: "bg-black-05 color-primary",
  [STATUS.WARNING]: "bg-warning-05",
  [STATUS.DANGER]: "bg-danger-05",
  [STATUS.SUCCESS]: "bg-success-05",
  [STATUS.NEUTRAL]: "bg-white bg-white--hover ba color-primary",
  [STATUS.PREMIUM]: "bg-gold-05",
  [STATUS.VIEWED]: "bg-purple-05",
  [STATUS.STARTED]: "bg-blue-05"
};

export const PILL_THEME = {
  SLIM: "slim",
  DEFAULT: "default"
};

const Pill = ({ copy, customClasses, theme, children, status, onClick }) => {
  const THEME_CLASSES =
    theme === PILL_THEME.SLIM ? SLIM_PILL_CLASSES : DEFAULT_CLASSES;
  const classes = { ...THEME_CLASSES, ...customClasses };

  const statusClasses =
    status && PILL_STATUS_CLASSES[status]
      ? PILL_STATUS_CLASSES[status]
      : PILL_STATUS_CLASSES[STATUS.DEFAULT];

  return (
    <div
      className={classnames(classes.container, {
        pointer: onClick
      })}
      onClick={onClick || NO_OP}
    >
      <div className={`${classes.copy} ${statusClasses}`}>
        {copy}
        {children}
      </div>
    </div>
  );
};

Pill.propTypes = {
  copy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customClasses: PropTypes.object,
  theme: PropTypes.string
};

Pill.defaultProps = {
  theme: PILL_THEME.DEFAULT
};

export default Pill;
