import React from "react";

const Flash = (props) => {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"navIcon--flash"}</title>
      <path
        d="M30.883 16.963a1.047 1.047 0 00-.928-.557h-7.319V4.031c0-.449-.294-.847-.728-.983a1.055 1.055 0 00-1.171.388L8.19 20.967a1.02 1.02 0 00-.074 1.07c.18.342.537.557.928.557h7.319v12.375c0 .45.294.846.728.983a1.048 1.048 0 001.17-.388L30.81 18.032a1.02 1.02 0 00.074-1.07zM18.447 32.542V22.025c0-.56-.472-1.012-1.054-1.012h-7.111L20.984 6.009l-.13 10.966c0 .56.472 1.012 1.054 1.012H28.7L18.447 32.542z"
        fill="#5D8BFF"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default Flash;
