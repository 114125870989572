import React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"navIcon--settings"}</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M6.309 22.899a1.923 1.923 0 00-.536 2.553l2.78 4.7c.237.402.61.71 1.055.867.488.16 1.022.11 1.471-.137l2.504-1.123c.435.285.889.543 1.357.772l.257 2.739A1.978 1.978 0 0017.132 35h5.54c1.02.003 1.87-.768 1.948-1.768l.25-2.698c.474-.227.93-.487 1.368-.775l2.53 1.146.06.025a2.005 2.005 0 002.456-.82l2.747-4.648a1.923 1.923 0 00-.52-2.554l-2.113-1.583a7.64 7.64 0 000-1.618l2.273-1.596.026-.02a1.926 1.926 0 00.529-2.553l-2.841-4.683a1.894 1.894 0 00-2.543-.734l-2.49 1.124a12.623 12.623 0 00-1.361-.773l-.254-2.739a1.225 1.225 0 00-.02-.137A1.978 1.978 0 0022.8 6h-5.537c-1.02-.003-1.87.768-1.948 1.768l-.25 2.707c-.473.226-.93.483-1.367.77l-2.524-1.14-.061-.026a2.005 2.005 0 00-2.455.82L5.91 15.542a1.929 1.929 0 00.526 2.554l.026.019 2.273 1.596a7.64 7.64 0 00-.043.795c0 .28 0 .533.04.775L6.36 22.864l-.052.035zm4.546.05c.412-.274.628-.756.555-1.24a8.216 8.216 0 01-.126-1.207 8.25 8.25 0 01.117-1.213 1.267 1.267 0 00-.533-1.223l-2.442-1.714 2.189-3.703 2.689 1.216c.451.205.982.138 1.367-.172a8.898 8.898 0 012.136-1.207c.439-.184.74-.59.783-1.056l.266-2.89h4.348l.267 2.89c.039.469.337.878.776 1.066.775.325 1.507.742 2.179 1.242.381.28.889.333 1.321.137l2.696-1.22 2.247 3.697-2.436 1.701c-.396.278-.602.75-.532 1.223.069.4.108.806.117 1.213a8.253 8.253 0 01-.117 1.213c-.067.458.125.917.5 1.197l2.29 1.714-2.193 3.732-2.688-1.217a1.317 1.317 0 00-1.368.173 8.908 8.908 0 01-2.136 1.206c-.439.185-.74.59-.783 1.057l-.266 2.889h-4.352l-.266-2.89a1.279 1.279 0 00-.782-1.056 10.345 10.345 0 01-2.18-1.241 1.318 1.318 0 00-1.318-.138l-2.689 1.217L8.31 24.65l2.546-1.701z"
          fill="#5D8BFF"
          fillRule="nonzero"
        />
        <path d="M6.309 22.899a1.923 1.923 0 00-.536 2.554l2.78 4.698c.237.403.61.71 1.055.868.488.16 1.022.11 1.471-.137l2.504-1.123c.435.285.889.543 1.357.772l.257 2.739A1.978 1.978 0 0017.132 35h5.54c1.02.003 1.87-.768 1.948-1.768l.25-2.698c.474-.227.93-.487 1.368-.775l2.53 1.146.06.025a2.005 2.005 0 002.456-.82l2.747-4.648a1.923 1.923 0 00-.52-2.554l-2.113-1.583a7.64 7.64 0 000-1.618l2.273-1.596.026-.02a1.926 1.926 0 00.529-2.553l-2.841-4.683a1.894 1.894 0 00-2.543-.734l-2.49 1.124a12.623 12.623 0 00-1.361-.773l-.254-2.739a1.225 1.225 0 00-.02-.137A1.978 1.978 0 0022.8 6h-5.537c-1.02-.003-1.87.768-1.948 1.768l-.25 2.707c-.473.226-.93.483-1.367.77l-2.524-1.14-.061-.026a2.005 2.005 0 00-2.455.82L5.91 15.542a1.929 1.929 0 00.526 2.554l.026.019 2.273 1.596a7.64 7.64 0 00-.043.795c0 .28 0 .533.04.775L6.36 22.864l-.052.035z" />
        <path d="M11.004 23.462c.406-.276.618-.76.547-1.246-.071-.4-.113-.805-.125-1.211.009-.408.047-.815.115-1.218a1.283 1.283 0 00-.524-1.227l-2.402-1.72 2.153-3.717 2.645 1.221c.444.205.967.138 1.345-.173a8.708 8.708 0 012.102-1.211c.431-.185.727-.592.77-1.06l.262-2.9h4.277l.262 2.9c.039.47.332.881.763 1.07a10.12 10.12 0 012.144 1.246c.375.282.874.334 1.3.138l2.651-1.224 2.211 3.71-2.396 1.707c-.39.279-.592.752-.524 1.228.068.402.106.809.115 1.217a8.447 8.447 0 01-.115 1.217c-.065.46.123.92.492 1.202l2.252 1.72-2.156 3.746-2.645-1.221a1.275 1.275 0 00-1.345.173c-.64.5-1.348.907-2.102 1.211a1.281 1.281 0 00-.77 1.06l-.262 2.9h-4.28l-.262-2.9a1.281 1.281 0 00-.77-1.06 10.126 10.126 0 01-2.143-1.246 1.275 1.275 0 00-1.297-.138l-2.645 1.22L8.5 25.17l2.504-1.708z" />
        <path
          d="M20 26a5.5 5.5 0 10-5.5-5.5A5.442 5.442 0 0020 26zm0-8.439a2.939 2.939 0 11-2.939 2.939 2.907 2.907 0 012.94-2.939z"
          fill="#5D8BFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
