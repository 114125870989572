import isError from "lodash/isError";
import get from "lodash/get";
const DEFAULT_ERROR_MESSAGE = "Whoops there was an error.";

/*
 * TODO: the rails error response.body should be standardized through the API
 * - The whole point of this is to transform server side errors into a shape to be consumed by a common Notification wrapper component
 * - I think this could be deleted and just started again
 * - The confusion came from not thinking through the transformation of errors rails  > hapi Boom -> FE component props
 */
export const noteMessage = (note) => {
  const response = {
    status: 400,
    autoClose: true,
    message: DEFAULT_ERROR_MESSAGE
  };
  if (process.env.NODE_ENV === "development") {
    // console.log("**** Client side note: ", JSON.stringify(note, null, 2));
  }

  if (isError(note)) {
    const status = get(note, "response.status");
    if (status) {
      response.status = status;
    }
    const message = get(note, "response.data.message");
    if (message) {
      response.message = message;
    }
    return response;
  } else {
    return { ...response, ...note };
  }
};

export const notesFromReducerState = (reducerKeys, state) => {
  const notes = [];
  for (let redIx = 0; redIx < reducerKeys.length; redIx++) {
    const key = reducerKeys[redIx];
    const note = state.getIn([key, "note"]);
    if (note) {
      notes.push({
        reducer: key,
        note
      });
    }
  }

  return notes;
};

/*
  1: 'success',
  2: 'warning'
  3: 'error'
  4: 'info'
  */
export const notieErrorCodeFromStatus = (status) => {
  let notieCode = 4;
  if (typeof status !== "undefined") {
    const codeRange = `${status}`.charAt(0);
    const notieMap = {
      2: 1,
      3: 2,
      4: 3,
      5: 3
    };

    notieCode = notieMap[codeRange] || notieCode;
  }

  return notieCode;
};
