import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { DESKTOP } from "utils/constants/ui";
import compact from "lodash/compact";
import NavLinkContent from "./NavLinkContent";
import { getSubNavWidth } from "utils/constants/ui";
import Analytics, { CLICK_NAV_LINK } from "utils/analytics";
import { useNavContext } from "utils/context/nav";
import ZoneLink from "components/ZoneLink";

/**
 * The Nav nodes within the dropdown
 */
const SubNavNode = ({
  icon,
  nodeTitle,
  href,
  zone,
  hoc,
  description,
  scheme,
  callout,
  nodeCount,
  isFirst
}) => {
  const { setValues } = useNavContext();
  const descriptions = compact(
    Array.isArray(description) ? description : [description]
  );

  const trackNavClick = () => {
    Analytics.track(CLICK_NAV_LINK, {
      href
    });
  };

  const contentProps = {
    loading: false,
    nodeCount: nodeCount,
    isFirst: isFirst,
    icon: icon,
    scheme: scheme,
    nodeTitle: nodeTitle,
    callout: callout,
    descriptions: descriptions
  };

  if (hoc) {
    return hoc(NavLinkContent, contentProps);
  } else {
    return (
      <ZoneLink zone={zone} href={href}>
        <NavLinkContent
          onClick={() => {
            trackNavClick();
            setValues({
              open: false,
              activeSection: null
            });
          }}
          {...contentProps}
        />
      </ZoneLink>
    );
  }
};

const SubNav = ({ title: subNavTitle, nodes, show }) => {
  const subNavWidth = getSubNavWidth(nodes);
  const { values, setValues } = useNavContext();

  const nodeClasses = classnames("link db w-100 fl black-80", {
    "w-50-l": nodes.length > 1
  });

  return (
    <div
      onMouseLeave={() => {
        if (show) {
          setValues({
            ...values,
            activeSection: null
          });
        }
      }}
      className="menu__subNav w-100 absolute-l top-0-l left-0-l flex flex-column bg-white z-1"
    >
      {subNavTitle && (
        <div className="f6 fw5 pa3 tracked dn dib-l">
          {subNavTitle.toUpperCase()}
        </div>
      )}
      <ul className="ma0 pa0 w-100 flex flex-wrap">
        {compact(nodes).map(
          (
            { icon, title, href, zone, description, scheme, callout, hoc },
            nodeIx
          ) => (
            <div key={nodeIx} className={nodeClasses}>
              <SubNavNode
                hoc={hoc}
                key={nodeIx}
                icon={icon}
                nodeTitle={title}
                href={href}
                zone={zone}
                description={description}
                scheme={scheme}
                callout={callout}
                nodeCount={nodes.length}
                isFirst={!nodeIx}
              />
            </div>
          )
        )}
      </ul>
      <style jsx>
        {`
          @media (min-width: ${DESKTOP}px) {
            .menu__subNav {
              margin-top: 54px;
              min-width: ${subNavWidth}px;
              box-shadow: rgba(37, 44, 97, 0.1) 0px 5px 15px 0px,
                rgba(37, 44, 97, 0.05) 0px 0px 15px 0px,
                rgba(93, 100, 148, 0.1) 0px 2px 4px 0px,
                rgba(93, 100, 148, 0.024) 0px -4px 16px 0px;
              transition: transform 0.25s ease-out 0s, opacity 0.25s ease-out 0s;
            }
            .menu__subNav::after {
              right: inherit;
              left: 24px;
              content: "";
              width: 10px;
              height: 10px;
              background-color: white;
              position: absolute;
              top: -5px;
              transform: rotate(-45deg);
              margin: auto;
            }
          }
        `}
      </style>
    </div>
  );
};

SubNav.propTypes = {
  title: PropTypes.string,
  nodes: PropTypes.array,
  show: PropTypes.bool
};

export default SubNav;
