import C from "constants/userAttachment";

export function setLoading() {
  return {
    type: C.UPDATE_USER_ATTACHMENT_STORE,
    payload: {
      isLoading: true
    }
  };
}

export const setUserAttachments = (payload) => {
  return {
    type: C.SET_USER_ATTACHMENTS,
    payload
  };
};

export const replaceUserAttachments = (payload) => {
  return {
    type: C.REPLACE_USER_ATTACHMENTS,
    payload
  };
};
